import TabList from 'components/organisms/tab-list'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { matchPath, Redirect, Route, withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import { clip, scrollTo, scrollToTop } from 'utilities/utils'

const ChildContainer = styled.div`
  background: ${({ theme }) => theme.colors.brandGolf};
  padding: ${({ hasSubTabs, theme }) =>
    !hasSubTabs ? theme.sizings.lvl3 : '0'};
  margin-bottom: ${({ $addStickyNavMargin, theme }) =>
    $addStickyNavMargin ? `calc(${theme.sizings.lvl1} + 70px)` : 'initial'};

  ${({ $fullScreenHeightOnMobile, $addStickyNavMargin, theme }) =>
    $fullScreenHeightOnMobile &&
    !$addStickyNavMargin &&
    `
    /**
      Reserve the full viewport's height for the tab content (on tablets and below).
      This way the tab labels are always displayed on top on the screen and
      as much tab content is shown at any time.

      The 50px are a derived value for the tab-labels height. Was impossible
      to figure out how it was calculated.
    */
    min-height: calc(100svh - ${theme.sizings.lvl4});
  `}

  ${media.desktop`
    min-height: initial;
  `}

  ${media.tablet`
    padding: ${({ hasSubTabs, theme }) => (!hasSubTabs ? theme.sizings.lvl4 : '0')};
  `}
`
/**
 * Tabs component.
 * @deprecated please use the  SimpleTabs component components/tabs/index.js
 */

function Tabs({
  className,
  children,
  items,
  addStickyNavMargin,
  hideNavigation,
  history,
  location,
  small,
  fullScreenHeightOnMobile,
  onChange,
  ...restProps
}) {
  const { search } = location
  const tabsRef = useRef()
  let activeIndex
  const [bringTabInView, setBringTabInView] = useState(false)

  items.forEach((item, index) => {
    const currentTab = matchPath(location.pathname, {
      path: item.matchTo || item.to,
    })
    activeIndex = currentTab ? index : activeIndex
  })

  function nextTab() {
    const newTabIndex = clip(activeIndex + 1, 0, items.length)
    if (onChange) {
      onChange(newTabIndex, activeIndex, 'next')
    }
    if (hideNavigation) {
      scrollToTop()
    } else {
      scrollTo(tabsRef.current)
      setBringTabInView(true)
    }
    history.push(`${items[newTabIndex].to}${search}`)
  }

  function previousTab() {
    const newTabIndex = clip(activeIndex - 1, 0, items.length)
    if (onChange) {
      onChange(newTabIndex, activeIndex, 'previous')
    }
    if (hideNavigation) {
      scrollToTop()
    } else {
      scrollTo(tabsRef.current)
      setBringTabInView(true)
    }
    history.push(`${items[newTabIndex].to}${search}`)
  }

  return (
    <>
      {typeof activeIndex === 'undefined' ? (
        <Redirect to={`${items[0].to}${search}`} />
      ) : (
        <div ref={tabsRef} className={className} data-test-e2e="tabs">
          {!hideNavigation && (
            <TabList
              className="TabList"
              activeIndex={activeIndex}
              items={items}
              small={small}
              bringTabInView={bringTabInView}
              data-test-e2e="tabs-list"
              location={location}
            />
          )}
          {children.map((child, index) => {
            const to = items[index]?.matchTo || items[index]?.to
            const url = items[index]?.subItems ? to.replace(/[^/]+$/, '') : to

            return (
              <Route
                key={index.toString()}
                path={url}
                render={() => (
                  <ChildContainer
                    hasSubTabs={
                      !items[index]?.hasSubTabs
                        ? !!items[index]?.subItems
                        : items[index]?.hasSubTabs
                    }
                    $fullScreenHeightOnMobile={fullScreenHeightOnMobile}
                    $addStickyNavMargin={addStickyNavMargin}
                    className="TabChildContainer"
                    data-test-e2e="tab-child-container"
                  >
                    {React.cloneElement(child, {
                      nextTab,
                      previousTab,
                      ...restProps,
                    })}
                  </ChildContainer>
                )}
              />
            )
          })}
        </div>
      )}
    </>
  )
}

Tabs.propTypes = {
  className: PropTypes.string,
  /**  Children property for rendering the child routes */
  children: PropTypes.node.isRequired,
  /** Items array with tabitem objects */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      count: PropTypes.number,
      matchTo: PropTypes.string,
      subItems: PropTypes.array,
      to: PropTypes.string,
      // optional way to tell it has hasSubTabs is they are not provided via subItems:
      hasSubTabs: PropTypes.bool,
    }),
  ).isRequired,
  /** Adds margin to the child container to make space for the sticky navigation */
  addStickyNavMargin: PropTypes.bool,
  /** Don't show the tab navigation if true */
  hideNavigation: PropTypes.bool,
  /** A history object to use for navigation from react-router. [Documentation](https://reacttraining.com/react-router/web/api/Router/history-object)  */
  history: PropTypes.object.isRequired,
  /** A location object to use for navigation from react-router. [Documentation](https://reacttraining.com/react-router/web/api/NavLink/location-object) */
  location: PropTypes.object.isRequired,
  /** Function called on every tab change */
  onChange: PropTypes.func,
  /** A boolean property if true the tabs will be rendered as a dropdownmenu */
  small: PropTypes.bool,
  /** A boolean property if true the tab container's (where the tab content lives) minimum height as tall as the user's screen */
  fullScreenHeightOnMobile: PropTypes.bool,
  /** A boolean property if true the main tabs will be styled as sub tabs */
  styleMainTabsAsSubTabs: PropTypes.bool,
}

Tabs.defaultProps = {
  hideNavigation: false,
  addStickyNavMargin: false,
  onChange: null,
  small: false,
  fullScreenHeightOnMobile: true,
}

export default withRouter(Tabs)
