import DashboardView from 'components/views/dashboard/index'
import { connect } from 'react-redux'
import { getLocations, getLocationTodos, getStayTime } from 'redux/actions/data'
import { openOverlay } from 'redux/actions/ui'

const mapStateToProps = ({ auth, data }) => ({
  auth,
  locations: data.locations,
  locationTodos: data.locationTodos,
  stayTime: data.stayTime,
})

const mapDispatchToProps = {
  getLocations,
  getStayTime,
  getLocationTodos,
  openOverlay,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView)
