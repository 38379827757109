import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { reset } from 'redux-form'
import styled from 'styled-components/macro'

import * as routes from 'config/routes'

import { mapCarFileType } from 'utilities/mapping'
import { media } from 'utilities/styled'
import { scrollTo } from 'utilities/utils'

import Button from 'components/atoms/button'
import Link from 'components/atoms/link'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Text from 'components/atoms/text'
import TextLink from 'components/atoms/text-link'
import EnhancedCard from 'components/layouts/enhanced-card'
import CarVersionSearch from 'components/organisms/car-version-search'
import CarVersionSelector from 'components/organisms/car-version-selector'
import PageHeader from 'components/organisms/page-header'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.brandFoxtrot};
  padding: 0 ${({ theme }) => theme.sizings.lvl3};

  ${media.desktop`
    padding: 0 60px;
  `};
`

// This negative margin makes the content full width in mobile
const ContentContainer = styled(EnhancedCard)`
  margin: 0 -${({ theme }) => theme.sizings.lvl3};
  ${({ theme, $withMarginTop }) =>
    $withMarginTop && `margin-top: ${theme.sizings.lvl2};`}

  ${media.tablet`
    margin: 0;
    ${({ theme, $withMarginTop }) => $withMarginTop && `margin-top: ${theme.sizings.lvl2};`}
  `};
`

const NothingFound = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.sizings.lvl3};
  text-align: center;

  & > * {
    margin-bottom: 1em;
  }
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: ${({ theme }) => theme.sizings.lvl3};
`

function CarEnrichmentStep1({
  addNewCarData,
  clearData,
  getLicensePlate,
  licensePlate,
  newCar,
  prefilledLicensePlate,
  prefilledMileage,
  prefilledVin,
  carFileId,
}) {
  const { t } = useTranslation()
  const carVersionSelector = useRef()
  const history = useHistory()
  const dispatch = useDispatch()
  const car = licensePlate && licensePlate.data
  const editions = car && car.uitvoeringen
  const loading = licensePlate && licensePlate.loading

  // Clear all car entry data when (re-)entering this view
  useEffect(() => {
    clearData('newCar')
    clearData('edition')
    clearData('licensePlate')
    clearData('nap')
    clearData('editions')
    dispatch(clearData('editionsForBpmDeclaration'))
    clearData('carAssets')
    clearData('carTypes')
    clearData('carModels')
    clearData('carBrands')
    clearData('createdCarfileByEdition')
    clearData('carfile')
    clearData('forms')
    dispatch(reset('carEnrichment'))
  }, [clearData, dispatch])

  useEffect(() => {
    if (editions && carVersionSelector.current) {
      scrollTo(carVersionSelector.current)
    }
  }, [editions])

  function handleGetLicensePlate(data) {
    getLicensePlate(data)
  }

  function handleEnrichWithoutEditions() {
    const step2Route = `${routes.CAR_ENRICHMENT}/${carFileId}/2`

    addNewCarData({
      ...car,
    })

    history.push(step2Route)
  }

  return (
    <Container>
      <PageHeader
        mainTitle={mapCarFileType(t, car?.voertuigsoort_mapped)}
        status={t('carFileEnrichment.titlePart2')}
        subTitle={t('step12SearchCar')}
        explanationContent={
          <Link to={`${routes.CAR_FILE}/${carFileId}`}>
            {t('carFileEnrichment.carFileExplanation', { carFileId })}
          </Link>
        }
      />
      <ContentContainer>
        <CarVersionSearch
          carFileId={carFileId}
          handleGetLicensePlate={handleGetLicensePlate}
          prefilledLicensePlate={prefilledLicensePlate}
          prefilledMileage={prefilledMileage}
          prefilledVin={prefilledVin}
          isEnrichment
        />
      </ContentContainer>

      <div ref={carVersionSelector}>
        {loading && <StyledLoadingIndicator />}
        {!loading && editions && editions.length > 0 && car && (
          <ContentContainer $withMarginTop>
            <CarVersionSelector
              addNewCarData={addNewCarData}
              car={car}
              carFileId={carFileId}
              editions={editions}
              newCar={newCar}
              isEnrichment
            />
          </ContentContainer>
        )}
      </div>

      {!loading && car && editions && editions.length === 0 && (
        <NothingFound>
          <Text text={t('thereAreNoKnownEditions')} />
          <Button
            text={t('continueEnriching')}
            onClick={handleEnrichWithoutEditions}
          />
        </NothingFound>
      )}
      {!loading && car && !editions && (
        <NothingFound>
          <Text text={t('weCantFindLicensePlate')} />
          <Text text={t('youCanEnterCarTrough')} />
          <TextLink
            text={t('noLicensePlate')}
            to={`${routes.CAR_ENRICHMENT}/${carFileId}/nolicenseplate`}
          />
          <TextLink
            text={t('orChooseManualEntry')}
            to={`${routes.CAR_ENRICHMENT}/${carFileId}/manual`}
          />
        </NothingFound>
      )}
    </Container>
  )
}

CarEnrichmentStep1.propTypes = {
  addNewCarData: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  getLicensePlate: PropTypes.func.isRequired,
  licensePlate: PropTypes.object,
  newCar: PropTypes.object,
  prefilledLicensePlate: PropTypes.string,
  prefilledMileage: PropTypes.number,
  prefilledVin: PropTypes.string,
  carFileId: PropTypes.string.isRequired,
}

CarEnrichmentStep1.defaultProps = {
  licensePlate: null,
  newCar: null,
  prefilledLicensePlate: null,
  prefilledMileage: null,
  prefilledVin: '',
  dataProviders: null,
}

export default CarEnrichmentStep1
