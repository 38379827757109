import Button from 'components/atoms/button'
import DropdownMenu from 'components/atoms/dropdown-menu'
import LoadingButton from 'components/atoms/loading-button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Text from 'components/atoms/text'
import Searchbox from 'components/molecules/searchbox'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'

const Container = styled.header`
  padding: ${({ theme }) => theme.sizings.lvl3} 0;

  ${media.tablet`
    margin-bottom: ${({ hasBorderBottom }) => (hasBorderBottom ? '30px' : '20px')};
    border-bottom: ${({ theme, hasBorderBottom }) => (hasBorderBottom ? `1px solid ${theme.colors.brandDelta}` : 0)};
    align-items: center;
    display: flex;
  `};
`

const InnerContainer = styled.div`
  flex: 1 0 auto;
`

const SearchboxContainer = styled.div`
  display: none;
  margin-left: ${({ theme }) => theme.sizings.lvl3};
  flex: 0 1 260px;

  ${media.desktop`
    display: block;
  `};
`

const MainTitleContainer = styled.div`
  ${media.phone`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.sizings.lvl1};
  `};
`

const DateRange = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  margin-right: ${({ theme }) => theme.sizings.lvl3};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.textTonedDown};
`

const MobileButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto !important;
  gap: ${({ theme }) => theme.sizings.lvl2};

  ${media.tablet`
    display: none;
  `}
`

const MobileButton = styled(Button)`
  flex: 0 0 auto !important;

  ${media.tablet`
    display: none;
  `}
`

const DesktopButton = styled(LoadingButton)`
  display: none;
  width: auto;

  ${media.tablet`
    display: flex;
  `}
`

const OptionalComponentsContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.sizings.lvl1};
  align-items: center;
  gap: ${({ theme }) => theme.sizings.lvl1};

  & > * {
    flex: 0 0 auto;
  }

  ${media.tablet`
    margin-top: 0;
  `};
`

/**
 * Page header component
 */

function PageHeader({
  buttonTo,
  buttonText,
  buttonOnClickHandler,
  buttonIsLoading,
  className,
  dateRange,
  locations,
  icon,
  mainTitle,
  selectedLocation,
  setSelectedLocation,
  showSearchbox,
  status,
  titleHasSpacing,
  subTitle,
  optionsSlot,
  hasBorderBottom,
  explanationContent,
  ...restProps
}) {
  const { t } = useTranslation()
  const mappedLocations =
    locations &&
    locations.map((location) => ({
      ...location,
      value: location.id,
    }))

  return (
    <Container
      hasBorderBottom={hasBorderBottom}
      className={className}
      {...restProps}
    >
      <InnerContainer data-test-e2e="page-header-titles">
        {subTitle && <Text type="h2b" text={subTitle} />}
        {mainTitle && (
          <MainTitleContainer data-test-e2e="page-header-main-title">
            <Text
              type="h1b"
              tag="h2"
              text={
                <>
                  {mainTitle}
                  {status && titleHasSpacing && ' '}
                </>
              }
            />
            {status && <Text type="h1" text={status} />}
          </MainTitleContainer>
        )}
        {explanationContent && <>{explanationContent}</>}
      </InnerContainer>
      {/* TODO: Move all these component into the view that they are triggered from */}
      {(optionsSlot ||
        dateRange ||
        buttonOnClickHandler ||
        buttonTo ||
        mappedLocations ||
        showSearchbox) && (
        <OptionalComponentsContainer>
          {optionsSlot && optionsSlot}
          {dateRange && <DateRange text={dateRange} tag="span" />}
          {buttonOnClickHandler && (
            <>
              <MobileButtonContainer>
                <MobileButton
                  background="actionsStandard"
                  icon={icon}
                  level="minimal"
                  onClick={buttonOnClickHandler}
                  disabled={buttonIsLoading}
                />
                {buttonIsLoading && <LoadingIndicator size="extraSmall" />}
              </MobileButtonContainer>
              <DesktopButton
                text={t(buttonText)}
                onClick={buttonOnClickHandler}
                isLoading={buttonIsLoading}
              />
            </>
          )}
          {buttonTo && (
            <>
              <MobileButtonContainer>
                <MobileButton
                  background="actionsStandard"
                  icon={icon}
                  level="minimal"
                  to={buttonTo}
                  disabled={buttonIsLoading}
                />
                {buttonIsLoading && <LoadingIndicator size="extraSmall" />}
              </MobileButtonContainer>
              <DesktopButton
                text={t(buttonText)}
                to={buttonTo}
                isLoading={buttonIsLoading}
              />
            </>
          )}
          {mappedLocations && (
            <DropdownMenu
              items={mappedLocations}
              onChange={(selected) => setSelectedLocation(selected)}
              selected={selectedLocation || mappedLocations[0].value}
            />
          )}
          {showSearchbox && (
            <SearchboxContainer>
              <Searchbox />
            </SearchboxContainer>
          )}
        </OptionalComponentsContainer>
      )}
    </Container>
  )
}

PageHeader.propTypes = {
  /**
   * Link to prop use this or buttonOnClickHandler
   *
   * @deprecated insert component via optionsSlot instead
   */
  buttonTo: PropTypes.string,
  /**
   * button text
   *
   * @deprecated insert component via optionsSlot instead
   */
  buttonText: PropTypes.string,
  /**
   * Buttonhandler if used, buttonTo not be used
   *
   * @deprecated insert component via optionsSlot instead
   */
  buttonOnClickHandler: PropTypes.func,
  /**
   * Button loading state
   *
   * @deprecated insert component via optionsSlot instead
   */
  buttonIsLoading: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Data range placeholder for marktview
   *
   * @deprecated insert component via optionsSlot instead
   */
  dateRange: PropTypes.string,
  /** icon prop for mobilebutton */
  icon: PropTypes.string,
  /** Locations of dealers used in ads for examples. See [icon component](https://ucc3-ui-library.netlify.com/#/Atoms/Icon) */
  locations: PropTypes.array,
  /** Main title  of the view */
  mainTitle: PropTypes.string,
  selectedLocation: PropTypes.string,
  setSelectedLocation: PropTypes.func,
  /** if true it shows a searchbox container */
  showSearchbox: PropTypes.bool,
  /** Status of car in stock  */
  status: PropTypes.string,
  /** Places a margin between mainTitle and status */
  titleHasSpacing: PropTypes.bool,
  /** secondary title */
  subTitle: PropTypes.string,
  /**
   * Used to place UI component into the slot to the right.
   */
  optionsSlot: PropTypes.node,
  /** Places a bottom border under the title. */
  hasBorderBottom: PropTypes.bool,
  /** Places an extra bit of content beneath the page heading */
  explanationContent: PropTypes.node,
}

PageHeader.defaultProps = {
  buttonTo: null,
  buttonText: 'again',
  buttonOnClickHandler: null,
  buttonIsLoading: false,
  className: null,
  dateRange: null,
  icon: 'reset',
  locations: null,
  mainTitle: null,
  selectedLocation: null,
  setSelectedLocation: null,
  showSearchbox: false,
  status: null,
  titleHasSpacing: true,
  subTitle: null,
  optionsSlot: null,
  hasBorderBottom: true,
  explanationContent: null,
}

export default PageHeader
