import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components/macro'

import Icon, { propTypes as iconPropTypes } from 'components/atoms/icon'
import Link from 'components/atoms/link'
import Text from 'components/atoms/text'

import { media } from 'utilities/styled'

function getBackgroundColor(level) {
  return {
    standard: 'actionsStandard',
    cta: 'actionsCta',
    minimal: 'transparent',
    option: 'transparent',
  }[level]
}

function getColor(level) {
  return {
    standard: 'actionsStandardText',
    cta: 'brandGolf',
    minimal: 'text',
    option: 'actionsStandard',
  }[level]
}

function getBackgroundHoverColor(level) {
  return {
    standard: 'actionsStandardSecondary',
    cta: 'brandSupport',
    minimal: 'transparent',
    option: 'transparent',
  }[level]
}

function getIconColor(level) {
  return {
    standard: 'brandGolf',
    cta: 'brandGolf',
    minimal: 'brandGolf',
    option: 'actionsStandard',
  }[level]
}

function getIconHoverColor(level) {
  return {
    standard: 'actionsStandardText',
    cta: 'sectionBackground',
    minimal: 'text',
    option: 'actionsStandard',
  }[level]
}

function getHeight(size = 'small') {
  return {
    small: 38,
    large: 48,
  }[size]
}

function translateDimensionsToSize(iconWidth, iconHeight) {
  const dimension = iconHeight || iconWidth
  if (dimension) {
    return {
      24: 'lg',
      16: 'md',
      8: 'sm',
    }[String(dimension)]
  }
  return 'lg'
}

function getWidth(level, size, icon, text) {
  if (level === 'minimal' && icon && !text) {
    return '40px'
  }
  if (size === 'large') {
    return '100%'
  }
  return 'auto'
}

const buttonStyles = css`
  align-items: center;
  background-color: ${({ background, level, theme }) =>
    theme.colors[background || getBackgroundColor(level)]};
  border: none;
  border-radius: ${({ size, $rounding, level }) =>
    level === 'option' ? '0' : getHeight(size) * $rounding}px;
  display: flex;
  flex: 0 0 auto;
  height: ${({ size }) => getHeight(size)}px;
  justify-content: center;
  outline: none;
  padding: 0
    ${({ level, size, icon, $noPadding }) =>
      ((level === 'option' || level === 'minimal') && icon) || $noPadding
        ? '0'
        : getHeight(size) / 2}px;
  width: ${({ level, text }) =>
    level === 'minimal' && !text ? '40px' : 'auto'};
  min-width: ${({ level, theme }) =>
    level === 'option' || level === 'minimal' ? '0' : theme.sizeByFactor(18)};
  cursor: pointer;

  &:active {
    background-color: ${({ level, theme }) =>
      theme.colors[getBackgroundHoverColor(level)]};
  }

  ${media.tablet`
    justify-content: ${({ alignLeft }) => (alignLeft ? 'flex-start' : 'center')};

    &:hover {
      background-color: ${({ level, theme }) => theme.colors[getBackgroundHoverColor(level)]};
      cursor: pointer;
    }
  `}

  ${media.desktop`
    width: ${({ level, size, icon }) => getWidth(level, size, icon)};
  `}
`

const StyledButton = styled.button`
  ${buttonStyles};

  &[disabled] {
    cursor: not-allowed;
    background-color: ${({ theme, level }) =>
      level !== 'option' ? theme.colors.inactiveBackground : 'transparent'};

    > *,
    &:hover > * {
      ${({ theme, level }) =>
        level === 'option' && `color: ${theme.colors.textTonedDown};`}
    }
  }
`

const StyledIcon = styled(Icon)`
  margin-right: ${({ level, $withMarginRight, theme }) =>
    $withMarginRight
      ? level === 'minimal' || level === 'option'
        ? '0'
        : theme.sizeByFactor(1)
      : undefined};
  margin-left: ${({ level, $withMarginLeft, theme }) =>
    $withMarginLeft
      ? level === 'minimal' || level === 'option'
        ? '0'
        : theme.sizeByFactor(1)
      : undefined};

  & * {
    fill: ${({ iconColor, level, theme }) =>
      theme.colors[iconColor || getIconColor(level)]};
  }

  ${StyledButton}:active:not([disabled]) & * {
    fill: ${({ level, theme }) => theme.colors[getIconHoverColor(level)]};
  }

  ${media.tablet`
    ${StyledButton}:hover:not([disabled]) & * {
        fill: ${({ level, theme }) => theme.colors[getIconHoverColor(level)]};
    }
  `}
`

const StyledText = styled(Text)`
  color: ${({ level, theme, color }) => theme.colors[color || getColor(level)]};
`

const OptionText = styled.span`
  font-family: 'work-sans';
  font-size: 14px;
  color: ${({ theme }) => theme.colors.actionsStandard};

  &:hover {
    color: ${({ theme }) => theme.colors.actionsStandardSecondary};
  }
`

const LinkButton = styled(Link)`
  ${buttonStyles};
  align-items: center;
  display: flex;
`

/**
 * Defaults to a standard small button,
 * but can also be rendered as `CTA` or `minimal` (no background)  or `option` button.
 *
 * ~~Minimal and~~ Option buttons can in turn be used as so called 'clickable icons',
 * while semantically remaining button, improving accessibility for blind users.
 *
 */
function Button({
  ariaLabel,
  className,
  alignLeft,
  background,
  children,
  color,
  disabled,
  href,
  icon,
  iconColor,
  iconHeight,
  iconWidth,
  iconSize,
  noPadding,
  rounding,
  rightIcon,
  rightIconColor,
  rightIconSize,
  level,
  size,
  text,
  to,
  type,
  ...restProps
}) {
  const { t } = useTranslation()
  const textType = size === 'large' ? 'buttonLarge' : 'button'
  const buttonInternals = (
    <>
      {icon && (
        <StyledIcon
          $withMarginRight={!!(children || t(text))}
          iconColor={iconColor}
          size={iconSize || translateDimensionsToSize(iconWidth, iconHeight)}
          width={iconWidth}
          height={iconHeight}
          level={level}
          type={icon}
        />
      )}
      {level === 'option' ? (
        <OptionText>
          {/* [EN spaces](https://en.wikipedia.org/wiki/En_(typography) give a nice visually balanced spacing */}
          {icon && (text || children) && <>&ensp;</>}
          {children || t(text)}
        </OptionText>
      ) : (
        <StyledText color={color} level={level} type={textType}>
          {/* [EN spaces](https://en.wikipedia.org/wiki/En_(typography) give a nice visually balanced spacing */}
          {icon && (text || children) && <>&ensp;</>}
          {children || t(text)}
        </StyledText>
      )}
      {rightIcon && (
        <StyledIcon
          $withMarginLeft={!!(children || t(text))}
          iconColor={rightIconColor}
          size={
            rightIconSize || translateDimensionsToSize(iconWidth, iconHeight)
          }
          level={level}
          type={rightIcon}
        />
      )}
    </>
  )

  if (to || href) {
    return (
      <LinkButton
        aria-label={ariaLabel}
        background={background}
        color={color}
        data-test-e2e="button"
        disabled={disabled}
        level={level}
        size={size}
        $rounding={rounding}
        to={to}
        href={href}
        icon={icon}
        className={className}
        {...restProps}
      >
        {buttonInternals}
      </LinkButton>
    )
  }
  return (
    <StyledButton
      aria-label={ariaLabel}
      background={background}
      data-test-e2e="button"
      disabled={disabled}
      icon={icon}
      level={level}
      size={size}
      $rounding={rounding}
      to={to}
      type={type}
      className={className}
      $noPadding={noPadding}
      {...restProps}
    >
      {buttonInternals}
    </StyledButton>
  )
}

export const propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  alignLeft: PropTypes.bool,
  background: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  /**
   * refer to [icon](/#/Atoms/Icon) size prop for the possible values
   */
  iconSize: iconPropTypes.size,
  /**
   * @deprecated (use iconSize instead)
   */
  iconHeight: PropTypes.string,
  /**
   * @deprecated (use iconSize instead)
   */
  iconWidth: PropTypes.string,
  rightIcon: PropTypes.string,
  rightIconColor: PropTypes.string,
  rightIconSize: iconPropTypes.size,
  noPadding: PropTypes.bool,
  level: PropTypes.oneOf(['standard', 'cta', 'minimal', 'option']),
  text: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  rounding: (props, propName, componentName) => {
    if (!(props[propName] >= 0 && props[propName] <= 0.5)) {
      return new Error(`Invalid value ${props[propName]} prop ${propName} supplied to
      ${componentName}. Validation failed.`)
    }
  },
}

Button.propTypes = propTypes

Button.defaultProps = {
  ariaLabel: null,
  className: null,
  alignLeft: false,
  background: null,
  children: null,
  color: null,
  disabled: false,
  icon: null,
  iconColor: null,
  iconHeight: null,
  iconSize: null,
  iconWidth: null,
  rightIcon: null,
  rightIconColor: null,
  rightIconSize: null,
  noPadding: false,
  level: 'standard',
  text: null,
  to: null,
  href: null,
  type: 'button',
  size: 'small',
  rounding: 0.5,
}

export default Button
