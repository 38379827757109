import Button from 'components/atoms/button'
import Link from 'components/atoms/link'
import EntryNoLicenseForm from 'components/molecules/entry-no-license-form'
import Typography from 'components/molecules/typography'
import LicensePlateSearch from 'components/organisms/license-plate-search'
import VinSearch from 'components/organisms/vin-search'
import { IGNORE_EXISTING_CAR_FILE } from 'config/consts'
import * as routes from 'config/routes'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { reset } from 'redux-form'

import { clearData, getLicensePlate } from 'redux/actions/data'
import styled from 'styled-components/macro'

const CarEntryFormSwitch = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.sizings.lvl0};
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const StyledLink = styled(Link)`
  font-weight: 400;
`

const InputLabelSeparator = styled.span`
  position: relative;
  font-weight: 300;
  /* top: ${({ theme }) => theme.sizeByFactor(0.25)}; */
`

const StyledNonLink = styled(Typography)`
  & + * {
    // negate styling logic defined in InlineBodyText
    margin-left: 0;
  }
`
const tabIds = {
  licensePlate: 'licensePlate',
  noLicensePlate: 'noLicensePlate',
  vin: 'vin',
  manual: 'manual',
}

/**
 * CarVersionSearch facilitates the switching between the license plate search,
 * VIN search, and manual entry forms.
 * That in turn let a user find a car version.
 */
const CarEntryForm = ({ isValuation }) => {
  const { t } = useTranslation()
  const [currentSearchTab, setCurrentSearchTab] = React.useState(
    tabIds.licensePlate,
  )

  const dispatch = useDispatch()
  const history = useHistory()

  const [gotLicensePlate, setGotLicensePlate] = React.useState(false)
  const licensePlateInfo = useSelector((state) => state?.data?.licensePlate)
  const existingCarId = useSelector(
    (state) => state?.data?.licensePlate?.data?.exists,
  )
  const [licensePlateRedirect, setLicensePlateRedirect] = React.useState(false)

  // Clear all car entry data when (re-)entering this view
  React.useEffect(() => {
    dispatch(clearData('newCar'))
    dispatch(clearData('licensePlate'))
    dispatch(reset('carEntry'))
    dispatch(reset('carValuation'))
  }, [dispatch])

  React.useEffect(() => {
    if (gotLicensePlate) {
      if (licensePlateInfo?.data) {
        if (existingCarId) {
          if (window.confirm(t('confirmGoToExistingCar'))) {
            // dispatch(clearData('licensePlate'))
            history.push(`${routes.CAR_FILE}/${existingCarId}`)
          } else {
            history.push(licensePlateRedirect)
          }
        } else {
          history.push(licensePlateRedirect)
        }
      }
    }
  }, [
    dispatch,
    existingCarId,
    gotLicensePlate,
    history,
    licensePlateInfo,
    licensePlateRedirect,
    t,
  ])

  const redirectCallback = (params, licensePlateEntryVariant) => {
    setLicensePlateRedirect(
      licensePlateEntryVariant +
        '?' +
        params.toString() +
        `&${IGNORE_EXISTING_CAR_FILE}=1`,
    )
  }

  const handleGetLicensePlate = (data) => {
    setGotLicensePlate(true)
    dispatch(getLicensePlate(data))
  }

  const licensePlateSearchIsPossible = useSelector((state) => {
    if (Array.isArray(state?.auth?.userDetails?.dealers)) {
      return state.auth.userDetails.dealers.some(
        (dealer) => dealer.country_code === 'nl',
      )
    }
    return false
  })

  const manualEntryLink = `${isValuation ? routes.VALUATION : routes.CAR_ENTRY}/manual`

  return (
    <>
      <CarEntryFormSwitch>
        {licensePlateSearchIsPossible && (
          <>
            <Button
              noPadding
              level="option"
              disabled={currentSearchTab === tabIds.licensePlate}
              onClick={() => setCurrentSearchTab(tabIds.licensePlate)}
            >
              {t('licensePlate')}
            </Button>
            <InputLabelSeparator>/</InputLabelSeparator>
          </>
        )}
        <Button
          level="option"
          noPadding
          disabled={currentSearchTab === tabIds.noLicensePlate}
          onClick={() => setCurrentSearchTab(tabIds.noLicensePlate)}
        >
          {t('noLicensePlate')}
        </Button>
        <InputLabelSeparator>/</InputLabelSeparator>
        <Button
          level="option"
          noPadding
          disabled={currentSearchTab === tabIds.vin}
          onClick={() => setCurrentSearchTab(tabIds.vin)}
        >
          {t('vinNumber')}
        </Button>
        <InputLabelSeparator>/</InputLabelSeparator>
        {currentSearchTab === tabIds.manual ? (
          <StyledNonLink type="InlineBodyText">
            {t('manualEntry')}
          </StyledNonLink>
        ) : (
          <StyledLink to={manualEntryLink}>{t('manualEntry')}</StyledLink>
        )}
      </CarEntryFormSwitch>
      {currentSearchTab === tabIds.licensePlate && (
        <LicensePlateSearch
          redirectCallback={(params) =>
            redirectCallback(
              params,
              `${
                isValuation ? routes.VALUATION : routes.CAR_ENTRY
              }/1${routes.LICENSE_PLATE_SEARCH_BY_LICENSE}`,
            )
          }
          isValuation={isValuation}
          handleGetLicensePlate={handleGetLicensePlate}
        />
      )}
      {currentSearchTab === tabIds.noLicensePlate && (
        <EntryNoLicenseForm
          isValuation={isValuation}
          handleGetLicensePlate={handleGetLicensePlate}
          redirectToOnSubmit={`${
            isValuation ? routes.VALUATION : routes.CAR_ENTRY
          }${routes.LICENSE_PLATE_SEARCH_WITHOUT_LICENSE}`}
        />
      )}
      {currentSearchTab === tabIds.vin && (
        <VinSearch
          isValuation={isValuation}
          handleGetLicensePlate={handleGetLicensePlate}
          redirectToOnSubmit={`${isValuation ? routes.VALUATION : routes.CAR_ENTRY}/1${routes.LICENSE_PLATE_SEARCH_BY_VIN}`}
        />
      )}
    </>
  )
}

CarEntryForm.propTypes = {
  isValuation: PropTypes.bool,
}

CarEntryForm.defaultProps = {
  isValuation: false,
}

export default CarEntryForm
