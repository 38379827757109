import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { change, initialize, reset } from 'redux-form'

import data from 'utilities/data'
import {
  getAllYearsFrom,
  mapToLabelAndValue,
  translateListItems,
} from 'utilities/utils'

import {
  addNewCarData,
  clearData,
  createCarfile,
  getEditions,
} from 'redux/actions/data'

import { destinationOptions } from 'config/data'
import { CAR_ENTRY, VALUATION } from 'config/routes'

import ComplexForm from 'components/molecules/complex-form'

import useDealerLocationCountryCode from 'hooks/use-location-country-code'

const WHEELER_DELTA_MOTOREN_ID = 'wd_motoren'
const WHEELER_DELTA_BROMMOBIEL_ID = 'wd_brommobiel'

const { getCarBrands, getCarTypes, getCarModels } = data

function getLabel(items, id, labelKey) {
  return items.find((item) => item.id === id)[labelKey]
}

const getOptions = async (params, getter, stateSetter, instanceId) => {
  const options = await getter(instanceId, params)
  stateSetter({ data: options })
}

function EntryNoLicenseForm({ isValuation, redirectToOnSubmit }) {
  const history = useHistory()
  const { search } = useLocation()
  const prefilledData = Object.fromEntries(new URLSearchParams(search))
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [mounted, setMounted] = useState(false)
  const destinationItems = translateListItems(destinationOptions, t)
  const [isMotorbike, setIsMotorbike] = useState(false)
  const [isMicrocar, setIsMicrocar] = useState(false)
  const [carBrands, setCarBrands] = useState({})
  const [carTypes, setCarTypes] = useState({})
  const [carModels, setCarModels] = useState({})
  const instanceId = useSelector((state) => state.auth.instanceId)
  const { locationCountryCodeIsNl } = useDealerLocationCountryCode()

  const submitText =
    isMotorbike || isMicrocar
      ? isValuation
        ? t('startValuation')
        : t('startInput')
      : t('showVersions')

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      const preloadOptions = async () => {
        await getOptions({}, getCarTypes, setCarTypes, instanceId)

        if (prefilledData.jaar && prefilledData.voertuigsoort) {
          await getOptions(
            {
              jaar: prefilledData.jaar,
              voertuigsoort: prefilledData.voertuigsoort,
            },
            getCarBrands,
            setCarBrands,
            instanceId,
          )
        }

        if (
          prefilledData.jaar &&
          prefilledData.voertuigsoort &&
          prefilledData.merk
        ) {
          await getOptions(
            {
              merk: prefilledData.merk,
              jaar: prefilledData.jaar,
              voertuigsoort: prefilledData.voertuigsoort,
            },
            getCarModels,
            setCarModels,
            instanceId,
          )
        }
      }
      preloadOptions()
    }
  }, [
    instanceId,
    mounted,
    prefilledData.jaar,
    prefilledData.merk,
    prefilledData.voertuigsoort,
  ])

  // Clear all car entry data when (re-)entering this view
  // Normally this happens in car-entry-step-1 and car-valuation-step-1, but that step
  // is skipped in the non-NL version of the app (menu-links are controlled from the back-end)
  useEffect(() => {
    if (locationCountryCodeIsNl) {
      return
    }

    dispatch(clearData('newCar'))
    dispatch(clearData('edition'))
    dispatch(clearData('licensePlate'))
    dispatch(clearData('nap'))
    dispatch(clearData('editions'))
    dispatch(clearData('editionsForBpmDeclaration'))
    dispatch(clearData('carAssets'))
    dispatch(clearData('carTypes'))
    dispatch(clearData('carModels'))
    dispatch(clearData('carBrands'))
    dispatch(clearData('createdCarfileByEdition'))
    dispatch(clearData('carfile'))
    dispatch(clearData('forms'))
    dispatch(reset('carEntry'))
    dispatch(reset('carValuation'))
  }, [dispatch, locationCountryCodeIsNl, prefilledData.voertuigsoort])

  const handleSubmit = useCallback(
    async (submitData) => {
      if (redirectToOnSubmit) {
        history.push(
          redirectToOnSubmit + '?' + new URLSearchParams(submitData).toString(),
        )
        return
      }

      if (isMotorbike) {
        const mappedSubmitData = {
          ...submitData,
          merk: getLabel(carBrands.data, submitData.merk, 'merk'),
          modelserie: getLabel(carModels.data, submitData.model, 'model'),
          jaardeel1: submitData.jaar.slice(-4), // Only send the year
          km_binnen: submitData.mileage,
          ...(isValuation && { status: 'taxatie' }),
        }

        delete mappedSubmitData.jaar
        delete mappedSubmitData.mileage

        const response = await dispatch(createCarfile(mappedSubmitData))

        if (response.data.data) {
          history.push(
            `${isValuation ? VALUATION : CAR_ENTRY}/2/basic?auto_id=${response.data.data.auto_id}`,
          )
        }

        return
      }

      if (isMicrocar) {
        const mappedSubmitData = {
          ...submitData,
          modelserie: submitData.model,
          jaardeel1: submitData.jaar.slice(-4), // Only send year
          km_binnen: submitData.mileage,
          ...(isValuation && { status: 'taxatie' }),
        }

        delete mappedSubmitData.model
        delete mappedSubmitData.jaar
        delete mappedSubmitData.mileage

        const response = await dispatch(createCarfile(mappedSubmitData))

        if (response.data.data) {
          history.push(
            `${isValuation ? VALUATION : CAR_ENTRY}/2/basic?auto_id=${response.data.data.auto_id}`,
          )
        }

        return
      }

      await dispatch(addNewCarData(submitData))
      // due to race conditions, carBrands and carModels might not be loaded yet
      if (carBrands.data && carModels.data) {
        dispatch(
          addNewCarData({
            labels: {
              merk: getLabel(carBrands.data, submitData.merk, 'merk'),
              model: getLabel(carModels.data, submitData.model, 'model'),
            },
          }),
        )
      }

      await dispatch(getEditions(submitData))
    },
    [
      carBrands.data,
      carModels.data,
      dispatch,
      history,
      isMicrocar,
      isMotorbike,
      isValuation,
      redirectToOnSubmit,
    ],
  )

  useEffect(() => {
    if (
      carBrands?.data?.length &&
      carModels?.data?.length &&
      prefilledData.merk &&
      prefilledData.model
    ) {
      // only when carBrands and carModels are loaded, we can set the labels
      // they are only set for displaying the car brand and model in CarVersionSelector
      // which is odd behaviour and needs to be refactored.
      dispatch(
        addNewCarData({
          labels: {
            merk: getLabel(carBrands.data, prefilledData.merk, 'merk'),
            model: getLabel(carModels.data, prefilledData.model, 'model'),
          },
        }),
      )
    }
  }, [
    carBrands.data,
    carModels.data,
    dispatch,
    prefilledData.merk,
    prefilledData.model,
  ])

  // This onChange is called for every change of the ComplexForm. Which
  // previously resulted in brands options and model options being
  // fetched for every request. Therefore there is a check of relevant
  // values actually have changed before new options are fetched.
  //
  // dispatch and props arguments are unused but have to be defined
  // in order to get previousValues.
  // https://redux-form.com/8.3.0/docs/api/reduxform.md/#-code-onchange-function-code-optional-
  const handleChange = useCallback(
    (values, dispatch, props, previousValues) => {
      if (!values) {
        return
      }

      const kind = values.voertuigsoort
      const yearOfBuild = values.jaar
      const brand = values.merk

      const kindChanged = kind !== previousValues?.voertuigsoort
      const yearOfBuildChanged = yearOfBuild !== previousValues?.jaar
      const brandChanged = brand !== previousValues?.merk

      setIsMotorbike(values.voertuigsoort === WHEELER_DELTA_MOTOREN_ID)
      setIsMicrocar(values.voertuigsoort === WHEELER_DELTA_BROMMOBIEL_ID)

      /**
       * When "voertuigsoort" is changed, empty all the fields after "voertuigsoort", some of these are interactive / based on a backend response
       */
      if (kindChanged) {
        dispatch(reset('entryNoLicense'))
      }

      /**
       * When "voertuigsoort" has a value, re-set the value for this field
       * Normally this is fixed width the enableReinitialize prop for the complex form, but it's buggy
       */
      if (kind && kindChanged) {
        dispatch(
          change('entryNoLicense', 'voertuigsoort', values.voertuigsoort),
        )
      }

      /**
       * Don't make any option calls when we're adding a microcar, the BE doesn't have data for this
       */
      if (values.voertuigsoort === WHEELER_DELTA_BROMMOBIEL_ID) {
        return
      }

      if (yearOfBuild && kind && (kindChanged || yearOfBuildChanged)) {
        getOptions(
          {
            jaar: yearOfBuild,
            voertuigsoort: kind,
          },
          getCarBrands,
          setCarBrands,
          instanceId,
        )
      }

      if (
        kind &&
        yearOfBuild &&
        brand &&
        (yearOfBuildChanged || kindChanged || brandChanged)
      ) {
        getOptions(
          {
            merk: brand,
            jaar: yearOfBuild,
            voertuigsoort: kind,
          },
          getCarModels,
          setCarModels,
          instanceId,
        )
      }
    },
    [instanceId],
  )

  useEffect(() => {
    if (
      prefilledData.voertuigsoort &&
      prefilledData.jaar &&
      prefilledData.merk &&
      prefilledData.model &&
      prefilledData.mileage
    ) {
      setTimeout(() => {
        dispatch(reset('entryNoLicense'))
        // fill the form with the prefilled data
        dispatch(initialize('entryNoLicense', prefilledData))
        handleSubmit(prefilledData)
      }, 1000)
    } else {
      // clear the form when the component is mounted
      dispatch(reset('entryNoLicense'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mappedCarBrands =
    carBrands &&
    carBrands.data &&
    mapToLabelAndValue(carBrands.data, 'merk', 'id')

  const mappedCarModels =
    carModels &&
    carModels.data &&
    mapToLabelAndValue(carModels.data, 'model', 'id')

  let fields = [
    {
      id: 'voertuigsoort',
      key: 'voertuigsoort',
      name: 'voertuigsoort',
      title: t('kind'),
      type: 'select',
      options: carTypes && carTypes.data ? carTypes.data : [],
      required: true,
    },
    {
      id: 'jaar',
      key: 'jaar',
      name: 'jaar',
      required: true,
      title: t('yearOfBuild'),
      type: 'select',
      options: getAllYearsFrom(1981).map((year) => ({
        label: year.toString(),
        value: `01-${year}`,
      })),
    },
    {
      id: 'merk',
      key: 'merk',
      name: 'merk',
      title: t('brand'),
      type: 'select',
      options: mappedCarBrands || [],
      required: true,
    },
    {
      id: 'model',
      key: 'model',
      name: 'model',
      title: isMotorbike ? t('categorySegment') : t('model'),
      type: 'select',
      options: mappedCarModels || [],
      required: true,
    },
    {
      id: 'mileage',
      key: 'mileage',
      name: 'mileage',
      title: t('mileage'),
      variety: 'floatingLabel',
      required: true,
    },
  ]

  if (isMotorbike) {
    fields = [
      ...fields.slice(0, 4),
      {
        id: 'type',
        key: 'type',
        name: 'type',
        title: `${t('type')}/${t('version')}`,
        required: true,
        variety: 'floatingLabel',
      },
      ...fields.slice(4),
    ]
  }

  if (isMicrocar) {
    fields = fields.map((field) => {
      if (field.name !== 'voertuigsoort' && field.name !== 'jaar') {
        return { ...field, type: 'text', variety: 'floatingLabel' }
      }

      return field
    })
  }

  if (isValuation) {
    fields = [
      ...fields.slice(0, isMotorbike ? 5 : 4),
      {
        id: 'destination',
        key: 'destination',
        name: 'destination',
        title: t('destination'),
        type: 'select',
        options:
          isMotorbike || isMicrocar
            ? destinationItems.filter((item) => item.value !== 'schadeauto')
            : destinationItems,
        required: true,
      },
      {
        id: 'datum_verwacht',
        key: 'datum_verwacht',
        name: 'datum_verwacht',
        title: t('expected'),
        type: 'date',
        required: true,
      },
      ...fields.slice(isMotorbike ? 5 : 4),
    ]
  }

  const fieldsets = [{ fields }]

  return (
    <ComplexForm
      data={prefilledData}
      fieldsets={fieldsets}
      formId="entryNoLicense"
      level="cta"
      onChange={handleChange}
      onSubmit={handleSubmit}
      submitText={submitText}
      // enableReinitialize // Enabling this should be the solution to "dynamic" input fields, but causes a bug when switching the "voertuigsoort" input
    />
  )
}

EntryNoLicenseForm.propTypes = {
  isValuation: PropTypes.bool,
  redirectToOnSubmit: PropTypes.string,
}

EntryNoLicenseForm.defaultProps = {
  isValuation: false,
  redirectToOnSubmit: null,
}

export default EntryNoLicenseForm
