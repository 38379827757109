import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'

import { createCarfileByEdition, getCarfile } from 'redux/actions/data'

import * as routes from 'config/routes'

import { translateForm } from 'utilities/form-utils'
import { media } from 'utilities/styled'
import { objectToArray } from 'utilities/utils'

import ComplexForm from 'components/molecules/complex-form'
import DetailsCard from 'components/molecules/details-card'
import Typography from 'components/molecules/typography'

const Title = styled(Typography)`
  font-size: 20px;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};

  ${media.tablet`
    margin-bottom:  ${({ theme }) => theme.sizings.lvl3};
  `}
`
const StyledDetailsCard = styled(DetailsCard)`
  &:not(:first-child) {
    margin-bottom: ${({ theme }) => theme.sizings.lvl2};
  }
`

const FiltersForm = styled(ComplexForm)`
  margin-bottom: ${({ theme }) => theme.sizings.lvl3};
`

function CarVersionSelector({
  addNewCarData,
  history,
  location,
  car,
  carFileId,
  editions,
  getEditions,
  newCar,
  isValuation,
  isEnrichment,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const createdCarfileByEdition = useSelector(
    (state) => state?.data?.createdCarfileByEdition?.data,
  )
  const [selectedAltcode, setSelectedAltcode] = useState(null)
  const step2Route = isValuation
    ? `${routes.VALUATION}/2`
    : `${routes.CAR_ENTRY}/2`

  const isVinRegex = new RegExp(
    `/[0-9]+${routes.LICENSE_PLATE_SEARCH_BY_VIN}$`,
    'i',
  )
  const isVinSearch = isVinRegex.test(location.pathname)

  // Car entry without license plate gives an object with a filters
  // and rows field. Car entry with license plate gives the editions
  // array directly:
  const editionsArray = Array.isArray(editions) ? editions : editions.rows

  const sortedEditions = editionsArray.sort((first, second) => first - second)
  const filterFieldsets = editions.filters && editions.filters.fieldsets
  const translatedFilterFieldsets =
    filterFieldsets && translateForm(filterFieldsets, t)

  const mostLikelyVersion = t('mostLikelyVersion')

  function handleSelect(index) {
    const altcode = editionsArray[index].altcode
    setSelectedAltcode(altcode)

    addNewCarData({
      ...car,
      ...editionsArray[index],
    })

    if (!createdCarfileByEdition) {
      dispatch(
        createCarfileByEdition({
          ...car,
          uitvoering: altcode,
          type:
            editionsArray[index].uitvoering || editionsArray[index]['atl-type'],
          model:
            (car.labels && car.labels.model) || car.modelserie || car.model,
          merk: (car.labels && car.labels.merk) || car.merk,
          km_binnen: newCar.data.mileage,
          mileage: newCar.data.mileage,
          destination: newCar.data.destination, // only passed during valuation
          expected: newCar.data.expected, // only passed during valuation
          valuation: isValuation,
          ...(isVinSearch && { vin_data_provider: newCar.data.provider }), // vin_data_provider should be sent to the BE when createCarFileByEdition is called
          ...(isEnrichment && carFileId && { enrich_auto_id: carFileId }), // This parameter ensures no NEW car file is created
        }),
      ).then(() => {
        // Get the carfile after enriching so the UI is up to date with what was just updated to the backend
        if (isEnrichment && carFileId) {
          dispatch(getCarfile(carFileId))
        }
      })

      // The callback that was previously here (setSelectedAltcode(null)) would update the loading button, yet we've already navigated
      // to the next page. This would introduce the memory leak error in the console.log.
    }
  }

  function handleChangeFilters(filters) {
    getEditions({
      ...filters,
      ...newCar.data,
    })
  }

  useEffect(() => {
    if (
      selectedAltcode !== null &&
      selectedAltcode >= 0 &&
      newCar?.data?.auto_id
    ) {
      history.push(`${step2Route}?auto_id=${newCar.data.auto_id}`)
    }
  }, [history, newCar, selectedAltcode, step2Route])

  return (
    <>
      <Title type="Level1Heading">{t('selectVersion')}</Title>
      {translatedFilterFieldsets && (
        <>
          <Title type="Level5Heading">{t('filterYourResults')}</Title>
          <FiltersForm
            fieldsets={translatedFilterFieldsets}
            formId="carVersionSelectorFilters"
            onChange={handleChangeFilters}
            enableReinitialize
          />
        </>
      )}
      {sortedEditions.map((sortedEdition, index) => {
        const details =
          sortedEdition.accessoires || objectToArray(sortedEdition)
        const emphasisText = index === 0 ? mostLikelyVersion : ''
        const mappedDetails = details.map(
          ({ omschrijving, value, ...otherFields }) => ({
            value: omschrijving,
            val: value,
            ...otherFields,
          }),
        )

        return (
          <StyledDetailsCard
            key={index.toString()}
            emphasisText={emphasisText}
            details={mappedDetails}
            heading={sortedEdition.uitvoering || sortedEdition['atl-type']}
            index={index}
            onSelect={handleSelect}
            subtitle={
              (car.labels && car.labels.model) || car.modelserie || car.model
            }
            title={(car.labels && car.labels.merk) || car.merk}
            isLoading={selectedAltcode === sortedEdition.altcode}
            loadingText={t('creatingCarfile')}
            disableSelectButton={selectedAltcode !== null}
            data-test-e2e="car-version"
          />
        )
      })}
    </>
  )
}

CarVersionSelector.propTypes = {
  addNewCarData: PropTypes.func.isRequired,
  getEditions: PropTypes.func,
  history: PropTypes.object.isRequired,
  car: PropTypes.object.isRequired,
  carFileId: PropTypes.string,
  editions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  newCar: PropTypes.object.isRequired,
  isValuation: PropTypes.bool,
  location: PropTypes.object.isRequired,
  isEnrichment: PropTypes.bool,
}

CarVersionSelector.defaultProps = {
  carFileId: null,
  getEditions: null,
  isValuation: false,
  isEnrichment: false,
}

export default withRouter(CarVersionSelector)
