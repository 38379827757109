import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { reset } from 'redux-form'
import styled from 'styled-components/macro'

import { clearData } from 'redux/actions/data'

import { mapCarFileType } from 'utilities/mapping'
import { media } from 'utilities/styled'
import { scrollTo } from 'utilities/utils'

import LoadingIndicator from 'components/atoms/loading-indicator'
import EnhancedCard from 'components/layouts/enhanced-card'
import CarVersionSearch from 'components/organisms/car-version-search'
import CarVersionSelector from 'components/organisms/car-version-selector'
import PageHeader from 'components/organisms/page-header'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.brandFoxtrot};
  padding: 0 ${({ theme }) => theme.sizings.lvl3};

  ${media.desktop`
    padding: 0 60px;
  `};
`

const ContentContainer = styled(EnhancedCard)`
  margin: 0 -${({ theme }) => theme.sizings.lvl3} ${({ theme }) =>
      theme.sizings.lvl3} -${({ theme }) => theme.sizings.lvl3};
  overflow: visible; // Enables the datepicker to be visible (otherwise its cut off)

  ${media.tablet`
    margin: 0 0 ${({ theme }) => theme.sizings.lvl3} 0;
  `};
`

function CarEntryValuationStep1NoLicense({
  addNewCarData,
  car,
  carBrands,
  carModels,
  carTypes,
  editions,
  getCarBrands,
  getCarModels,
  getCarTypes,
  getEditions,
  newCar,
}) {
  const { t } = useTranslation()
  const extraFilters = useRef()
  const editionsData = editions && editions.data
  const loading = editions && editions.loading
  const dispatch = useDispatch()

  // clear initial form when restarting this process:
  useEffect(() => {
    clearData('entryNoLicense')
    dispatch(reset('entryNoLicense'))
  }, [dispatch])

  useEffect(() => {
    if (editionsData && extraFilters.current) {
      scrollTo(extraFilters.current)
    }
  }, [editionsData])

  return (
    <Container>
      <PageHeader
        mainTitle={mapCarFileType(t, car?.voertuigsoort_mapped)}
        status={t('valuation')}
        subTitle={t('step12SearchCar')}
      />
      <ContentContainer>
        <CarVersionSearch isValuation />
      </ContentContainer>
      <div ref={extraFilters}>
        {loading && <LoadingIndicator height="100px" />}
        {editionsData && car && (
          <ContentContainer data-test-e2e="car-version-selector">
            <CarVersionSelector
              addNewCarData={addNewCarData}
              car={car}
              editions={editionsData}
              getEditions={getEditions}
              newCar={{ data: newCar }}
              isValuation
            />
          </ContentContainer>
        )}
      </div>
    </Container>
  )
}

CarEntryValuationStep1NoLicense.propTypes = {
  addNewCarData: PropTypes.func.isRequired,
  car: PropTypes.object,
  carBrands: PropTypes.object,
  carModels: PropTypes.object,
  carTypes: PropTypes.object,
  getCarBrands: PropTypes.func.isRequired,
  getCarModels: PropTypes.func.isRequired,
  getCarTypes: PropTypes.func.isRequired,
  getEditions: PropTypes.func.isRequired,
  editions: PropTypes.object,
  newCar: PropTypes.object,
}

CarEntryValuationStep1NoLicense.defaultProps = {
  carBrands: null,
  carModels: null,
  carTypes: null,
  car: null,
  editions: null,
  newCar: null,
}

export default CarEntryValuationStep1NoLicense
