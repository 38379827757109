import { ApolloProvider } from '@apollo/client'
import StartServiceCalculation from 'components/views/car-service-plan/start-service-calculation'
import { CSPapolloClient } from 'config/apollo'
import React from 'react'

const StartCspCalculation = (props) => {
  // Once it is loaded there is no need to load it again on each re-render:
  const cspApolloClient = React.useMemo(() => CSPapolloClient(), [])
  return (
    <ApolloProvider client={cspApolloClient}>
      <StartServiceCalculation {...props} />
    </ApolloProvider>
  )
}

export default StartCspCalculation
