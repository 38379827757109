import Counter from 'components/atoms/counter'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import { media } from 'utilities/styled'
import { TabsContext } from './tabs-context-provider'

const StyledCounter = styled(Counter)`
  display: none;

  ${media.tablet`
    display: flex;
    margin: 0 0 0 8px;
  `};
`

const tabTriggerDefaultStyles = css`
  display: flex;
  padding: 16px;
  text-transform: uppercase;
  background-image: ${({ theme }) => theme.gradients.tabBottomShadow};
  &:hover {
    background-image: none;
  }
`
const tabTriggerDefaultSelectedStyles = css`
  font-weight: 500;
  background: ${({ theme }) => theme.colors.brandGolf};
`

const tabTriggerSmallStyles = css`
  font-size: ${({ theme }) => theme.smallFontSize}
  font-size: 14px;
  padding: 24px 0 20px;
  font-weight: 500;
`

const tabTriggerSmallSelectedStyles = css`
  font-weight: 400;
  padding: 24px 0 16px; // 4px less padding on the bottom, accounting for the border
  border-bottom: 4px solid;
  border-color: ${({ theme }) => theme.colors.brandAlfa};
`

const StyledTrigger = styled.button`
  font-family: ${({ theme }) => theme.font};
  font-size: ${({ theme }) => theme.baseFontSize};
  line-height: ${({ theme }) => theme.baseLineHeight};
  color: ${({ theme }) => theme.colors.actionsStandard};
  font-weight: 400;

  display: flex;
  cursor: pointer;

  // Load size specific styles:
  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return tabTriggerSmallStyles
      default:
        return tabTriggerDefaultStyles
    }
  }}

  &[aria-selected='true'] {
    color: ${({ theme }) => theme.colors.brandAlfa};
    // Load size specific styles:
    ${({ $size }) => {
      switch ($size) {
        case 'small':
          return tabTriggerSmallSelectedStyles
        default:
          return tabTriggerDefaultSelectedStyles
      }
    }}
  }

  &:hover {
    // Load size specific styles:
    ${({ theme, $size }) => {
      switch ($size) {
        case 'small':
          return ''
        default:
          return `
            background-image: none;
            background: ${theme.colors.brandGolf};
          `
      }
    }}
  }
`

/**
 * Render a tab in the form of a button with  a tab role.
 * Should be used inside a TabsList component.
 * Based on the TabsTrigger component from Radix UI.
 */
const TabsTrigger = ({
  className,
  children,
  size,
  count,
  value,
  disabled,
  ...props
}) => {
  const context = React.useContext(TabsContext)
  return (
    <StyledTrigger
      onMouseDown={(event) => {
        // only call handler if it's the left button (mousedown gets triggered by all mouse buttons)
        // but not when the control key is pressed (avoiding MacOS right click)
        if (!disabled && event.button === 0 && event.ctrlKey === false) {
          context.onValueChange(value)
        } else {
          // prevent focus to avoid accidental activation
          event.preventDefault()
        }
      }}
      className={className}
      $disabled={disabled}
      $size={size}
      aria-selected={context.value === value}
      role="tab"
      aria-controls={`panel-${value}`} // matches the id of the content
      {...props}
    >
      {children}
      {typeof count === 'number' && <StyledCounter>{count}</StyledCounter>}
    </StyledTrigger>
  )
}

export const TabsSizePropType = PropTypes.oneOf(['default', 'small'])
export const TabsSizePropTypeDefault = 'default'

TabsTrigger.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  size: TabsSizePropType,
  count: PropTypes.number,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
}

TabsTrigger.defaultProps = {
  size: TabsSizePropTypeDefault,
  selected: false,
  count: null,
  disabled: false,
}

export default TabsTrigger
