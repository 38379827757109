import { useLazyQuery, useQuery } from '@apollo/client'
import Button from 'components/atoms/button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import TextLink from 'components/atoms/text-link'
import {
  FormikSelectInput,
  FormikTextInput,
} from 'components/molecules/formik-field'
import NewDropdownSelect from 'components/molecules/new-dropdown-select'
import Typography from 'components/molecules/typography'
import { releaseCSPEmail } from 'config/data'
import {
  GET_CAR_BRANDS,
  GET_CAR_MODELS,
  GET_FUEL_TYPES,
} from 'config/graphql/csp'
import { CSP_NO_LICENSE_PLATE } from 'config/routes'
import { CSPSalesFlowStartFindCarTypeSchema } from 'config/validation-schemas'
import { Field, Form, Formik, useField, useFormikContext } from 'formik'
import useSelectedLocationId from 'hooks/use-selected-location-id'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'

const Fields = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.sizings.lvl2};
  grid-template-rows: auto;
  grid-template-columns: 1fr;

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
  `}

  ${media.desktop`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
`

const ContainerCell = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl1};
  ${media.desktop`
    flex-basis: 25%;
  `}
`

const FormSubmitLine = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.sizings.lvl2};
  gap: ${({ theme }) => theme.sizings.lvl2};

  > * {
    flex: 1 1 auto;
  }

  ${media.desktop`
    flex-wrap: nowrap;
    > * {
      flex: 0 0 auto;
    }
  `}
`

const FormError = styled(Typography)`
  width: 100%;
  text-align: center;
  order: 2;
  margin-top: ${({ theme }) => theme.sizings.lvl1};

  ${media.desktop`
    width: auto;
    margin-right: auto;
    flex-grow: 0;
    text-align: left;
    order: initial;
    margin-top: 0;
  `}
`
const ErrorTextLink = styled(TextLink)`
  display: inline;
  font-size: 12px;
  font-family: sans-serif;
  text-decoration: underline;
`

const SubmitContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: ${({ theme }) => theme.sizings.lvl1};
`

const StartCalculationButton = styled(Button)`
  width: 100%;
  text-align: center;
  ${media.desktop`
    width: auto;
  `}
`

export const urlParamKeys = {
  carBrand: 'carBrand',
  carModel: 'carModel',
  carFuelType: 'carFuelType',
  currentMileage: 'currentMileage',
  prospect: 'prospect',
  dealerId: 'dealerId',
  carBuildYear: 'carBuildYear',
}

/**
 * This is the same component as in with-license-plate.js
 * When this was abstracted into its own file, the UI started crashing
 * in unexpected ways. This is a workaround to keep the UI stable.
 * @TODO: Investigate why this is happening and fix it.
 **/
const CustomCspDealerField = (props) => {
  const { setFieldValue, values } = useFormikContext()
  const locationIdToDealerId = useSelector((state) => {
    const lookUp = {}
    if (Array.isArray(state?.auth?.userDetails?.dealers)) {
      state.auth.userDetails.dealers.forEach((dealer) => {
        lookUp[dealer.id] = dealer.dealernr
      })
    }
    return lookUp
  })
  const [field] = useField(props)

  const [selectedLocationId] = useSelectedLocationId()

  useEffect(() => {
    const dealerId = locationIdToDealerId[selectedLocationId] || ''
    if (dealerId && dealerId !== values.dealerId) {
      setFieldValue('dealerId', dealerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationId])

  return (
    <NewDropdownSelect
      {...props}
      {...field}
      onChange={(formData) => {
        setFieldValue('dealerId', formData, false)
      }}
    />
  )
}

const StartServiceCalculationWithoutLicensePlate = ({
  switchFormMode,
  prospectTypes,
  dealers,
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const paramsValues = {}
  for (const key in urlParamKeys) {
    paramsValues[key] = searchParams.get(key) || null
  }

  const [carBrand, setCarBrand] = useState(paramsValues.carBrand)

  const validationSchema = CSPSalesFlowStartFindCarTypeSchema
  const [formError, setFormError] = useState()
  const [everyDealerDisabled, setEveryDealerDisabled] = useState(false)

  const carBrands = useQuery(GET_CAR_BRANDS)
  const carFuelTypes = useQuery(GET_FUEL_TYPES)
  const [getCarModels, carModels] = useLazyQuery(GET_CAR_MODELS)

  useEffect(() => {
    if (carBrand) {
      getCarModels({
        variables: {
          brandId: carBrand,
        },
      })
    }
  }, [carBrand, getCarModels])

  useEffect(() => {
    const everyDealerIsDisabled = dealers.every((dealer) => dealer.isDisabled)
    if (dealers && everyDealerIsDisabled) {
      setEveryDealerDisabled(true)
      setFormError(t('carServicePlanDashboard.disabledCspMessage'))
    }
  }, [dealers, t])

  if (carBrands.loading || carBrands.error) {
    return <LoadingIndicator error={carBrands.error} size="small" />
  }

  const carBrandsOptions = Array.isArray(carBrands?.data?.brands)
    ? carBrands.data.brands.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    : []

  const carModelsOptions = Array.isArray(carModels?.data?.models)
    ? carModels.data.models.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    : []

  const carFuelTypesOptions = Array.isArray(carFuelTypes?.data?.fuelTypes)
    ? carFuelTypes.data.fuelTypes
    : []

  // Setup the initial values
  const prospectTypeValues = prospectTypes.map(
    (prospectType) => prospectType.value,
  )
  let initialProspectType = paramsValues.prospectType
  if (!initialProspectType && prospectTypeValues.length > 0) {
    initialProspectType = prospectTypeValues.includes(paramsValues.prospect)
      ? paramsValues.prospect
      : prospectTypeValues[0]
  }

  const dealerValues = dealers.map((dealer) => dealer.value)
  let initialDealer = paramsValues.dealerId
  if (!initialDealer && dealerValues.length > 0) {
    initialDealer =
      dealerValues.includes(paramsValues.dealerId) &&
      !dealers.find((dealer) => dealer.value === paramsValues.dealerId)
        .isDisabled
        ? paramsValues.dealerId
        : dealers.find((dealer) => !dealer.disabled).value
  }

  const initialValues = {
    ...paramsValues,
    mileage: paramsValues.mileagePerYear || '',
    prospect: initialProspectType,
    dealer: initialDealer,
    carBuildYear: parseInt(paramsValues.carBuildYear, 10),
  }

  // Formik's validateOnMount property doesn't work properly.
  // So, some manual validation is required to initially set the form to (in)valid.
  let isInitiallyValid = true
  try {
    // This throws a ValidationError if validation fails. The catch block will be invoked then.
    validationSchema(t).validateSync(initialValues)
  } catch (error) {
    // Validation failed
    isInitiallyValid = false
  }

  // handlers:
  const onSubmitUpdates = (values) => {
    setFormError(null)

    const params = new URLSearchParams(location.search)
    for (const key in urlParamKeys) {
      // push through the query string, but without any previously set licensePlate,
      // because sending it along causes problems with car data retrieval further into
      // the CSP process.
      params.delete('licensePlate')
      if (values[key]) {
        params.set(key, values[key])
      }
    }

    history.push(`${CSP_NO_LICENSE_PLATE}?${params.toString()}`)
  }

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema(t)}
      onSubmit={onSubmitUpdates}
      // Setting the intial errors to make the form invalid
      initialErrors={isInitiallyValid ? {} : initialValues}
    >
      {({ isValid }) => {
        const submitButton = (
          <>
            {switchFormMode && (
              <Button level="option" onClick={switchFormMode} noPadding>
                {t('carServicePlanDashboard.switchToWithLicensePlateEntry')}
              </Button>
            )}
            <SubmitContainer>
              <StartCalculationButton
                type="submit"
                disabled={!isValid || everyDealerDisabled}
                level="cta"
                data-test-e2e="button-start-calculation"
              >
                {t('carServicePlanDashboard.findCarModelType')}
              </StartCalculationButton>
            </SubmitContainer>
          </>
        )
        return (
          <Form data-test-e2e="start-service-calculation-form">
            <Fields>
              <ContainerCell>
                {carBrandsOptions.length ? (
                  <Field
                    name="carBrand"
                    label={t('carServicePlanDashboard.carBrand')}
                    items={carBrandsOptions}
                    required
                    selectionRequired
                    filled
                    component={FormikSelectInput}
                    disabled={everyDealerDisabled}
                    id="carBrand"
                    onChange={(id) => setCarBrand(id)}
                  />
                ) : (
                  <LoadingIndicator size="small" />
                )}
              </ContainerCell>
              <ContainerCell>
                <Field
                  name="carModel"
                  label={t('carServicePlanDashboard.carModel')}
                  items={carModelsOptions}
                  required
                  selectionRequired
                  filled
                  component={FormikSelectInput}
                  disabled={everyDealerDisabled || !carModels?.data}
                  id="carModel"
                />
              </ContainerCell>
              <ContainerCell>
                <Field
                  name="carBuildYear"
                  label={t('carServicePlanDashboard.carBuildYear')}
                  required
                  filled
                  type="integer"
                  component={FormikTextInput}
                  disabled={everyDealerDisabled}
                  id="carBuildYear"
                />
              </ContainerCell>
              <ContainerCell>
                <Field
                  name="carFuelType"
                  label={t('carServicePlanDashboard.carFuelType')}
                  items={carFuelTypesOptions}
                  required
                  selectionRequired
                  filled
                  component={FormikSelectInput}
                  disabled={everyDealerDisabled}
                  id="carFuelType"
                />
              </ContainerCell>
              <ContainerCell>
                <Field
                  name="currentMileage"
                  label={t('mileage')}
                  unit="km"
                  required
                  filled
                  type="float"
                  component={FormikTextInput}
                  disabled={everyDealerDisabled}
                  id="currentMileage"
                />
              </ContainerCell>
              <ContainerCell>
                <Field
                  name="prospect"
                  label={t('carServicePlanDashboard.prospectType')}
                  items={prospectTypes}
                  required
                  selectionRequired
                  filled
                  component={FormikSelectInput}
                  disabled={everyDealerDisabled}
                  id="prospect-type"
                />
              </ContainerCell>
              <ContainerCell>
                <CustomCspDealerField
                  name="dealerId"
                  label={t('carServicePlanDashboard.dealer')}
                  items={dealers}
                  required
                  selectionRequired
                  filled
                  disabled={everyDealerDisabled}
                  id="dealerId"
                />
              </ContainerCell>
            </Fields>
            <FormSubmitLine>
              {formError && (
                <FormError type="ErrorText">
                  {formError}
                  {everyDealerDisabled && (
                    <ErrorTextLink
                      text={releaseCSPEmail}
                      href={`mailto:${releaseCSPEmail}`}
                    />
                  )}
                </FormError>
              )}

              {submitButton}
            </FormSubmitLine>
          </Form>
        )
      }}
    </Formik>
  )
}

StartServiceCalculationWithoutLicensePlate.propTypes = {
  switchFormMode: PropTypes.func,
  dealers: PropTypes.array.isRequired,
  prospectTypes: PropTypes.array.isRequired,
}

StartServiceCalculationWithoutLicensePlate.defaultProps = {
  switchFormMode: null,
}

export default StartServiceCalculationWithoutLicensePlate
