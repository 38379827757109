import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import { TabsContext } from './tabs-context-provider'
/**
 * Use TabsContent to render the content of a tab.
 * Use it together with TabsTrigger and TabsList and TabsRoot.
 */

const StyledContent = styled.div`
  overflow: auto;
  max-height: 100vh;
  background: ${({ theme }) => theme.colors.brandGolf};
  padding-top: ${({ hasSubTabs, theme }) =>
    !hasSubTabs ? theme.sizings.lvl3 : '0'};
  margin-bottom: ${({ $addStickyNavMargin, theme }) =>
    $addStickyNavMargin ? `calc(${theme.sizings.lvl1} + 70px)` : 'initial'};

  ${({ $fullScreenHeightOnMobile, $addStickyNavMargin, theme }) =>
    $fullScreenHeightOnMobile &&
    !$addStickyNavMargin &&
    `
    /**
      Reserve the full viewport's height for the tab content (on tablets and below).
      This way the tab labels are always displayed on top on the screen and
      as much tab content is shown at any time.

      The 50px are a derived value for the tab-labels height. Was impossible
      to figure out how it was calculated.
    */
    min-height: calc(100svh - ${theme.sizings.lvl4});
  `}

  ${media.desktop`
    min-height: initial;
  `}

  ${media.tablet`
    padding-top: ${({ hasSubTabs, theme }) => (!hasSubTabs ? theme.sizings.lvl4 : '0')};
  `}
  &[aria-hidden='true'] {
    display: none;
  }
`
const TabsContent = ({ className, value, children, ...props }) => {
  const context = React.useContext(TabsContext)
  return (
    <StyledContent
      className={className}
      role="tabpanel"
      {...props}
      id={`panel-${value}`} // matches the aria-controls of the trigger
      aria-hidden={context.value !== value}
    >
      {context.value === value ? <>{children}</> : ''}
    </StyledContent>
  )
}

TabsContent.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default TabsContent
