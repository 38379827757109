import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  clearData,
  getCarfile,
  getDynamicEndpoint,
  postDynamicEndpoint,
} from 'redux/actions/data'
import { getForms } from 'redux/actions/forms'
import { openOverlay } from 'redux/actions/ui'

import CarFileCardContextMenu from './carfile-card-context-menu'

const mapStateToProps = ({ auth, data, forms, ui }, { isCarShare }) => {
  let dataProp = {}

  if (data.carfile) {
    dataProp = {
      ...data.carfile.data,
    }
  }

  if (auth) {
    dataProp.token = auth.instanceId
  }

  return {
    dynamicEndpoint: data.dynamicEndpoint,
    data: dataProp,
    contextMenuItems: data?.actionMenuItems?.data?.contextmenu || [],
    forms,
    ui,
  }
}

const mapDispatchToProps = {
  clearData,
  getCarfile,
  getDynamicEndpoint,
  getForms,
  openOverlay,
  postDynamicEndpoint,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CarFileCardContextMenu))
