import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
import { TabsContextProvider } from './tabs-context-provider'

const StyledRoot = styled.section``
/**
 * Renders the Root of the Tabs component. You can compose a tab UI with TabsList, TabsTrigger and TabsContent.
 * Inspired by the [Radix UI tabs component](https://www.radix-ui.com/primitives/docs/components/tabs).
 */
const SimpleTabsRoot = ({
  className,
  children,
  value,
  onValueChange,
  ...props
}) => {
  return (
    <TabsContextProvider value={value} onValueChange={onValueChange}>
      <StyledRoot className={className} {...props} role="tabs">
        {children}
      </StyledRoot>
    </TabsContextProvider>
  )
}

SimpleTabsRoot.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
}

export default SimpleTabsRoot
