import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { media } from 'utilities/styled'
import { getBackendNapValue } from 'utilities/utils'

import Button from 'components/atoms/button'
import ContentSeparator from 'components/atoms/content-separator'
import {
  FormikLicensePlateInput,
  FormikTextInput,
} from 'components/molecules/formik-field'
import NapCheck from 'components/molecules/nap-check'
import { licensePlateSearchFormValidation } from 'config/validation-schemas'
import { useDispatch, useSelector } from 'react-redux'
import { addNewCarData, clearData, getNap } from 'redux/actions/data'

const InputRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.sizings.lvl1};

  ${media.tablet`
    gap: ${({ theme }) => theme.sizeByFactor(0.5)};
    grid-template-columns: 1fr min-content 1fr min-content 1fr;
    align-items: top;
  `}
`
const StyledSeparator = styled(ContentSeparator)`
  ${media.tablet`
    height: ${({ theme }) => theme.sizeByFactor(6)};
  `}
`

const InputCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl1};
  width: 100%;
`

const StyledNapCheck = styled(NapCheck)`
  text-align: right;

  & button {
    padding: 0;
  }
`

const SubmitButton = styled(Button)`
  width: 100%;

  ${media.tablet`
    margin: ${({ theme }) => theme.sizings.lvl1} 0;
  `}
`

// The values in these fields (in Dutch) are inline with what the backend expects
// and what's added to the Redux store.
export const LICENSE_PLATE_SEARCH_FORM_FIELDS = {
  LICENSE_PLATE: 'kenteken',
  MILEAGE: 'mileage',
  VIN: 'vin',
}

const LicensePlateSearch = ({
  handleGetLicensePlate,
  prefilledLicensePlate,
  prefilledMileage,
  redirectCallback,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const nap = useSelector((state) => state.data?.nap)
  const napValue = nap && nap.data && nap.data.nap
  const napValueForBackend = getBackendNapValue(napValue)

  const separator = (
    <StyledSeparator variant="vertical" paddingLevel={2} $alignBottom={false} />
  )
  const validationSchema = licensePlateSearchFormValidation(t)

  const initialValues = {
    [LICENSE_PLATE_SEARCH_FORM_FIELDS.LICENSE_PLATE]:
      prefilledLicensePlate || '',
    [LICENSE_PLATE_SEARCH_FORM_FIELDS.MILEAGE]: prefilledMileage || '',
  }

  const checkNap = ({ mileage, licensePlate }) => {
    dispatch(getNap({ mileage, licensePlate }))
  }

  const onSubmit = async (values) => {
    // Fetches the license plate data
    await handleGetLicensePlate({
      [LICENSE_PLATE_SEARCH_FORM_FIELDS.LICENSE_PLATE]:
        values[LICENSE_PLATE_SEARCH_FORM_FIELDS.LICENSE_PLATE],
    })

    // Adds the car data to the Redux store
    dispatch(
      addNewCarData({
        [LICENSE_PLATE_SEARCH_FORM_FIELDS.LICENSE_PLATE]:
          values[LICENSE_PLATE_SEARCH_FORM_FIELDS.LICENSE_PLATE],
        [LICENSE_PLATE_SEARCH_FORM_FIELDS.MILEAGE]:
          values[LICENSE_PLATE_SEARCH_FORM_FIELDS.MILEAGE],
        nap: napValueForBackend, // Custom field from carFile, can be pre-filled
      }),
    )
    if (redirectCallback) {
      redirectCallback(
        new URLSearchParams({
          [LICENSE_PLATE_SEARCH_FORM_FIELDS.LICENSE_PLATE]:
            values[LICENSE_PLATE_SEARCH_FORM_FIELDS.LICENSE_PLATE],
          [LICENSE_PLATE_SEARCH_FORM_FIELDS.MILEAGE]:
            values[LICENSE_PLATE_SEARCH_FORM_FIELDS.MILEAGE],
          nap: napValueForBackend, // Custom field from carFile, can be pre-filled
        }),
      )
    }
  }

  useEffect(() => {
    if (
      initialValues[LICENSE_PLATE_SEARCH_FORM_FIELDS.LICENSE_PLATE].length &&
      (initialValues[LICENSE_PLATE_SEARCH_FORM_FIELDS.MILEAGE].length ||
        initialValues[LICENSE_PLATE_SEARCH_FORM_FIELDS.MILEAGE] > 0)
    ) {
      const doDataUpdates = async () => {
        await handleGetLicensePlate({
          [LICENSE_PLATE_SEARCH_FORM_FIELDS.LICENSE_PLATE]:
            initialValues[LICENSE_PLATE_SEARCH_FORM_FIELDS.LICENSE_PLATE],
        })

        // Adds the car data to the Redux store
        await dispatch(
          addNewCarData({
            ...initialValues,
            nap: napValueForBackend, // Custom field from carFile, can be pre-filled
          }),
        )
      }
      doDataUpdates()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Clear the licensePlate data when the user switches from VIN to licensePlate or vice-versa, when a license plate has already been received
  useEffect(() => {
    dispatch(clearData('licensePlate'))
  }, [dispatch])

  return (
    <>
      <Formik
        key="form-license"
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange
        validateOnBlur
        validateOnMount
      >
        {({ values, isValid, isSubmitting }) => (
          <Form noValidate>
            <InputRow>
              <InputCell>
                <Field
                  name={LICENSE_PLATE_SEARCH_FORM_FIELDS.LICENSE_PLATE}
                  component={FormikLicensePlateInput}
                  required
                  filled
                />
              </InputCell>
              {separator}
              <InputCell>
                <Field
                  name={LICENSE_PLATE_SEARCH_FORM_FIELDS.MILEAGE}
                  label={t('mileage')}
                  type="text"
                  component={FormikTextInput}
                  required
                  filled
                  data-test-e2e="input-mileage"
                />
                {nap && getNap && (
                  <StyledNapCheck
                    getNap={checkNap}
                    licensePlate={
                      values[LICENSE_PLATE_SEARCH_FORM_FIELDS.LICENSE_PLATE]
                    }
                    mileage={Number(
                      values[LICENSE_PLATE_SEARCH_FORM_FIELDS.MILEAGE],
                    )}
                    nap={nap}
                  />
                )}
              </InputCell>
              {separator}
              <InputCell>
                <SubmitButton
                  data-test-e2e="button-search-version"
                  disabled={!isValid || isSubmitting}
                  text={t('searchVersion')}
                  level="cta"
                  type="submit"
                />
              </InputCell>
            </InputRow>
          </Form>
        )}
      </Formik>
    </>
  )
}

LicensePlateSearch.propTypes = {
  redirectCallback: PropTypes.func,
  handleGetLicensePlate: PropTypes.func.isRequired,
  prefilledLicensePlate: PropTypes.string,
  prefilledMileage: PropTypes.number,
}

LicensePlateSearch.defaultProps = {
  redirectCallback: null,
  prefilledLicensePlate: '',
  prefilledMileage: null,
}

export default LicensePlateSearch
