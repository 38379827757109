import PropTypes from 'prop-types'
import * as React from 'react'

export const TabsContext = React.createContext({
  value: null,
  onValueChange: () => {},
})

export const TabsContextProvider = ({ children, value, onValueChange }) => {
  const context = {
    value,
    onValueChange,
  }
  return <TabsContext.Provider value={context}>{children}</TabsContext.Provider>
}

TabsContextProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
}
