import DropdownMenu from 'components/atoms/dropdown-menu'
import Icon from 'components/atoms/icon'
import TabsTrigger, {
  TabsSizePropType,
  TabsSizePropTypeDefault,
} from 'components/layouts/simple-tabs/tabs-trigger'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'
import { useMediaLayout } from 'use-media'
import { media } from 'utilities/styled'
import { scrollTo } from 'utilities/utils'
import { TabsContext } from './tabs-context-provider'

const TabsContainer = styled.div`
  position: relative;
`

const Container = styled.div`
  width: auto;
  display: flex;
  background: ${(props) => props.theme.colors.brandGolf};
  padding: 0;
  scrollbar-width: none;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.tablet`
    background: none;
    padding: 0;
  `}
`

const StyledDropdownMenu = styled(DropdownMenu)`
  width: 100%;
`
const ItemsContainer = styled.div`
  display: flex;
  width: auto;
  min-width: 100%;
  flex: 1 0 auto;
  height: auto;
  border-bottom: ${({ $derivedSize }) => ($derivedSize === 'small' ? '1px' : 0)}
    solid ${({ theme }) => theme.colors.brandDelta};
  gap: ${({ theme, $derivedSize }) =>
    $derivedSize === 'small' ? theme.sizings.lvl3 : '2px'};
  align-items: flex-end;
`

const LeftArrowContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.defaultBackground};
  box-shadow: ${({ theme }) => theme.colors.inactiveBackground} 8px 0px 4px -4px;
  cursor: pointer;
  display: flex;
  height: 100%;
  left: 0;
  padding: 0 ${({ theme }) => theme.sizings.lvl2};
  position: absolute;
  top: 0;
`

const RightArrowContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.defaultBackground};
  box-shadow: ${({ theme }) => theme.colors.inactiveBackground} -8px 0px 4px -4px;
  cursor: pointer;
  display: flex;
  height: 100%;
  position: absolute;
  padding: 0 ${({ theme }) => theme.sizings.lvl2};
  right: 0;
  top: 0;
`

function TabList({
  items,
  small,
  bringTabInView,
  alwaysSmall,
  size,
  ...restProps
}) {
  // @TODO Wrap hooks from external libraries in our own
  // custom hooks by means of a 'facade' to make refactoring
  // easy later and the reduce the risk of the fairly new
  // React functionality
  const containerRef = useRef()
  const innerContainerRef = useRef()
  const theme = useContext(ThemeContext)
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tablet - 1 })
  const [hasLeftOverflow, setHasLeftOverflow] = useState()
  const [hasRightOverflow, setHasRightOverflow] = useState()

  useEffect(() => {
    // The refs don't exist for a small tablist so don't execute
    // this effect in that case
    const currentContainerRef = containerRef.current

    function setOverflowState() {
      const containerRect = containerRef.current.getBoundingClientRect()
      const innerContainerRect =
        innerContainerRef.current.getBoundingClientRect()

      setHasRightOverflow(innerContainerRect.right - 5 > containerRect.right)
      setHasLeftOverflow(innerContainerRect.left + 5 < containerRect.left)
    }

    if (!small) {
      setOverflowState()
      window.addEventListener('resize', setOverflowState)
      currentContainerRef.addEventListener('scroll', setOverflowState)
    }

    return () => {
      if (!small) {
        window.removeEventListener('resize', setOverflowState)
        currentContainerRef.removeEventListener('scroll', setOverflowState)
      }
    }
  }, [small])

  function handleScrollLeft() {
    const innerContainerChildren = innerContainerRef.current.children

    scrollTo(innerContainerChildren[0], {
      horizontal: 'start',
      vertical: 'nearest',
    })
  }

  function handleScrollRight() {
    const innerContainerChildren = innerContainerRef.current.children

    scrollTo(innerContainerChildren[innerContainerChildren.length - 1], {
      horizontal: 'end',
      vertical: 'nearest',
    })
  }

  const derivedSize =
    size || (small || alwaysSmall || isMobile ? 'small' : 'default')
  const context = useContext(TabsContext)

  return (
    <TabsContainer {...restProps} role="tablist">
      {hasLeftOverflow && (
        <LeftArrowContainer onClick={handleScrollLeft}>
          <Icon size="md" type="arrowsLeft" />
        </LeftArrowContainer>
      )}
      <Container className="Container" ref={containerRef}>
        <ItemsContainer
          className="ItemContainer"
          ref={innerContainerRef}
          $derivedSize={derivedSize}
        >
          {isMobile && !alwaysSmall ? (
            <StyledDropdownMenu
              items={items}
              onChange={context.onValueChange}
              selected={context.value}
            />
          ) : (
            <>
              {items.map((item, index) => (
                <TabsTrigger
                  key={index.toString()}
                  disabled={item.disabled}
                  value={item.value || index}
                  size={derivedSize}
                  bringInView={bringTabInView}
                  count={item.count}
                >
                  {item.label}
                </TabsTrigger>
              ))}
            </>
          )}
        </ItemsContainer>
      </Container>
      {hasRightOverflow && (
        <RightArrowContainer onClick={handleScrollRight}>
          <Icon size="md" type="arrowsRight" />
        </RightArrowContainer>
      )}
    </TabsContainer>
  )
}

TabList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      count: PropTypes.number,
    }),
  ).isRequired,
  size: TabsSizePropType,
  small: PropTypes.bool, // If true, the tabs will be rendered with underlines, and will be rendered as a dropdown on mobile
  alwaysSmall: PropTypes.bool, // If true, the tabs will be rendered with underlines and overflow will be handled with left/right arrows
  bringTabInView: PropTypes.bool,
}

TabList.defaultProps = {
  small: false,
  size: TabsSizePropTypeDefault,
  alwaysSmall: false,
  bringTabInView: false,
}

export default TabList
