import Link from 'components/atoms/link'
import EntryNoLicenseForm from 'components/molecules/entry-no-license-form'
import Typography from 'components/molecules/typography'
import LicensePlateSearch from 'components/organisms/license-plate-search'
import VinSearch from 'components/organisms/vin-search'
import * as routes from 'config/routes'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components/macro'

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const Title = styled(Typography)`
  font-size: ${({ theme }) => theme.sizeByFactor(2.5)};
  margin: 0;
`

const CarVersionSearchSwitch = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.sizings.lvl0};
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
`

const StyledLink = styled(Link)`
  font-weight: 400;
`

const InputLabelSeparator = styled.span`
  position: relative;
  font-weight: 300;
  top: ${({ theme }) => theme.sizeByFactor(0.25)};
`

const StyledNonLink = styled(Typography)`
  & + * {
    // negate styling logic defined in InlineBodyText
    margin-left: 0;
  }
`

/**
 * CarVersionSearch facilitates the switching between the license plate search,
 * VIN search, and manual entry forms.
 * That in turn let a user find a car version.
 */
const CarVersionSearch = ({
  carFileId,
  handleGetLicensePlate,
  prefilledLicensePlate,
  prefilledMileage,
  prefilledVin,
  isValuation,
  isEnrichment,
  children,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()

  const licensePlateSearchIsPossible = useSelector((state) => {
    if (Array.isArray(state?.auth?.userDetails?.dealers)) {
      return state.auth.userDetails.dealers.some(
        (dealer) => dealer.country_code === 'nl',
      )
    }
    return false
  })

  const manualEntryLink = `${isValuation ? routes.VALUATION : routes.CAR_ENTRY}/manual`

  const vinLink =
    isEnrichment && carFileId
      ? `${routes.CAR_ENRICHMENT}/${carFileId}/1${routes.LICENSE_PLATE_SEARCH_BY_VIN}`
      : `${isValuation ? routes.VALUATION : routes.CAR_ENTRY}/1${routes.LICENSE_PLATE_SEARCH_BY_VIN}`

  const licensePlateLink =
    isEnrichment && carFileId
      ? `${routes.CAR_ENRICHMENT}/${carFileId}/1${routes.LICENSE_PLATE_SEARCH_BY_LICENSE}`
      : `${
          isValuation ? routes.VALUATION : routes.CAR_ENTRY
        }/1${routes.LICENSE_PLATE_SEARCH_BY_LICENSE}`

  const noLicensePlateLink =
    isEnrichment && carFileId
      ? `${routes.CAR_ENRICHMENT}/${carFileId}${routes.LICENSE_PLATE_SEARCH_WITHOUT_LICENSE}`
      : `${
          isValuation ? routes.VALUATION : routes.CAR_ENTRY
        }${routes.LICENSE_PLATE_SEARCH_WITHOUT_LICENSE}`

  const isLicensePlateSearch =
    location.pathname ===
    `${match.url}${routes.LICENSE_PLATE_SEARCH_BY_LICENSE}`
  const isVinSearch =
    location.pathname === `${match.url}${routes.LICENSE_PLATE_SEARCH_BY_VIN}`
  const isNoLicensePlateSearch = location.pathname === noLicensePlateLink
  const isManualEntry = location.pathname.startsWith(manualEntryLink)

  let headingText = isLicensePlateSearch
    ? t('enterLicensePlate')
    : t('enterVin')
  if (isNoLicensePlateSearch) {
    headingText = t('noLicensePlate')
  }
  if (isManualEntry) {
    headingText = t('manualEntry')
  }

  // Frontend should redirect to either the license plate or VIN form when landing on one of the following routes: /entry, /valuation, /car-enrichment
  useEffect(() => {
    if (
      isLicensePlateSearch ||
      isVinSearch ||
      isNoLicensePlateSearch ||
      isManualEntry
    ) {
      return
    }
    history.replace(licensePlateLink)
  }, [
    history,
    match,
    location,
    isVinSearch,
    isNoLicensePlateSearch,
    isLicensePlateSearch,
    isManualEntry,
    isEnrichment,
    licensePlateLink,
  ])

  return (
    <>
      <CarVersionSearchSwitch>
        {licensePlateSearchIsPossible && (
          <>
            {isLicensePlateSearch ? (
              <StyledNonLink type="InlineBodyText">
                {t('licensePlate')}
              </StyledNonLink>
            ) : (
              <StyledLink to={licensePlateLink}>{t('licensePlate')}</StyledLink>
            )}
            <InputLabelSeparator>/</InputLabelSeparator>
          </>
        )}
        {isNoLicensePlateSearch ? (
          <StyledNonLink type="InlineBodyText">
            {t('noLicensePlate')}
          </StyledNonLink>
        ) : (
          <StyledLink to={noLicensePlateLink}>{t('noLicensePlate')}</StyledLink>
        )}
        <InputLabelSeparator>/</InputLabelSeparator>
        {isVinSearch ? (
          <StyledNonLink type="InlineBodyText">{t('vinNumber')}</StyledNonLink>
        ) : (
          <StyledLink to={vinLink}>{t('vinNumber')}</StyledLink>
        )}
        {!isEnrichment && (
          <>
            <InputLabelSeparator>/</InputLabelSeparator>
            {isManualEntry ? (
              <StyledNonLink type="InlineBodyText">
                {t('manualEntry')}
              </StyledNonLink>
            ) : (
              <StyledLink to={manualEntryLink}>{t('manualEntry')}</StyledLink>
            )}
          </>
        )}
      </CarVersionSearchSwitch>
      <TitleContainer>
        <Title type="Level1Heading">{headingText}</Title>
      </TitleContainer>

      {isLicensePlateSearch && (
        <LicensePlateSearch
          carFileId={carFileId}
          handleGetLicensePlate={handleGetLicensePlate}
          prefilledLicensePlate={prefilledLicensePlate}
          prefilledMileage={prefilledMileage}
          isEnrichment={isEnrichment}
          licensePlateTo={licensePlateLink}
        />
      )}

      {isVinSearch && (
        <VinSearch
          carFileId={carFileId}
          handleGetLicensePlate={handleGetLicensePlate}
          prefilledMileage={prefilledMileage}
          prefilledVin={prefilledVin}
          isEnrichment={isEnrichment}
        />
      )}
      {isNoLicensePlateSearch && (
        <EntryNoLicenseForm isValuation={isValuation} />
      )}
      {isManualEntry && { children }}
    </>
  )
}

CarVersionSearch.propTypes = {
  carFileId: PropTypes.string,
  handleGetLicensePlate: PropTypes.func,
  prefilledLicensePlate: PropTypes.string,
  prefilledMileage: PropTypes.number,
  prefilledVin: PropTypes.string,
  isValuation: PropTypes.bool,
  isEnrichment: PropTypes.bool,
  children: PropTypes.node,
}

CarVersionSearch.defaultProps = {
  carFileId: null,
  getNap: undefined,
  prefilledLicensePlate: '',
  prefilledMileage: null,
  prefilledVin: '',
  handleGetLicensePlate: () => {},
  isValuation: false,
  isEnrichment: false,
  children: null,
}

export default CarVersionSearch
