import { useLazyQuery } from '@apollo/client'
import Button from 'components/atoms/button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import TextLink from 'components/atoms/text-link'
import {
  FormikLicensePlateInput,
  FormikSelectInput,
  FormikTextInput,
} from 'components/molecules/formik-field'
import NewDropdownSelect from 'components/molecules/new-dropdown-select'
import Typography from 'components/molecules/typography'
import { getMileagePerYearOptions, releaseCSPEmail } from 'config/data'
import { GET_CAR_FROM_LICENSE_PLATE_QUERY } from 'config/graphql/csp'
import { CSP_SALES_FLOW } from 'config/routes'
import {
  CSPSalesFlowStartCalculationDemoSchema,
  CSPSalesFlowStartCalculationSchema,
} from 'config/validation-schemas'
import { Field, Form, Formik, useField, useFormikContext } from 'formik'
import useSelectedLocationId from 'hooks/use-selected-location-id'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'
import { formatLicenseplate } from 'utilities/utils'

const FormFields = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.sizings.lvl2};
  grid-template-rows: auto;
  grid-template-columns: 1fr;

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
  `}

  ${media.desktop`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
`

const ContainerCell = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizings.lvl1};
  ${media.desktop`
    flex-basis: 25%;
  `}
`

const StyledFormikLicensePlateInput = styled(FormikLicensePlateInput)`
  max-width: 100%;
`

const FormSubmitLine = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.sizings.lvl2};
  gap: ${({ theme }) => theme.sizings.lvl2};

  > * {
    flex: 1 1 auto;
  }

  ${media.desktop`
    flex-wrap: nowrap;
    > * {
      flex: 0 0 auto;
    }
  `}
`

const FormError = styled(Typography)`
  width: 100%;
  text-align: center;
  order: 2;
  margin-top: ${({ theme }) => theme.sizings.lvl1};

  ${media.desktop`
    width: auto;
    margin-right: auto;
    flex-grow: 0;
    text-align: left;
    order: initial;
    margin-top: 0;
  `}
`
const ErrorTextLink = styled(TextLink)`
  display: inline;
  font-size: 12px;
  font-family: sans-serif;
  text-decoration: underline;
`

const SubmitContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: ${({ theme }) => theme.sizings.lvl1};
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  width: 100%;
  order: 2;
  margin-top: ${({ theme }) => theme.sizings.lvl1};
  ${media.desktop`
    order: initial;
    margin-top: 0;
  `}
`

const StartCalculationButton = styled(Button)`
  width: 100%;
  text-align: center;
  ${media.desktop`
    width: auto;
  `}
`

/**
 * This is the same component as in without-license-plate.js
 * When this was abstracted into its own file, the UI started crashing
 * in unexpected ways. This is a workaround to keep the UI stable.
 * @TODO: Investigate why this is happening and fix it.
 **/
const CustomCspDealerField = (props) => {
  const { setFieldValue, values } = useFormikContext()
  const locationIdToDealerId = useSelector((state) => {
    const lookUp = {}
    if (Array.isArray(state?.auth?.userDetails?.dealers)) {
      state.auth.userDetails.dealers.forEach((dealer) => {
        lookUp[dealer.id] = dealer.dealernr
      })
    }
    return lookUp
  })
  const [field] = useField(props)

  const [selectedLocationId] = useSelectedLocationId()

  useEffect(() => {
    const dealerId = locationIdToDealerId[selectedLocationId] || ''
    if (dealerId && dealerId !== values.dealer) {
      setFieldValue('dealer', dealerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationId])

  return (
    <NewDropdownSelect
      {...props}
      {...field}
      onChange={(formData) => {
        setFieldValue('dealer', formData, false)
      }}
    />
  )
}

const StartServiceCalculationWithLicensePlate = ({
  demo,
  switchFormMode,
  prospectTypes,
  dealers,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const searchParamsLicensePlate = searchParams.get('licensePlate')
  const searchParamsMileage = searchParams.get('currentMileage')
  const searchParamsMileagePerYear = searchParams.get('mileagePerYear')
  const searchParamsProspectType = searchParams.get('prospect')
  const searchParamsDealer = searchParams.get('dealerId')
  const validationSchema = demo
    ? CSPSalesFlowStartCalculationDemoSchema
    : CSPSalesFlowStartCalculationSchema
  const [formError, setFormError] = useState()
  const [everyDealerDisabled, setEveryDealerDisabled] = useState(false)
  const [salesFlowStep1Url, setSalesFlowStep1Url] = useState()

  const [getCarFromLicensePlate, { loading: carLoading }] = useLazyQuery(
    GET_CAR_FROM_LICENSE_PLATE_QUERY,
    {
      onCompleted: () => {
        if (salesFlowStep1Url) {
          setFormError(null)
          history.push(salesFlowStep1Url)
        }
      },
      onError: (error) => {
        setSalesFlowStep1Url(null)
        setFormError(t('cspSalesFlow.carWithLicensePlate'))
        console.error(error)
      },
    },
  )

  useEffect(() => {
    const everyDealerIsDisabled = dealers.every((dealer) => dealer.isDisabled)
    if (dealers && everyDealerIsDisabled) {
      setEveryDealerDisabled(true)
      setFormError(t('carServicePlanDashboard.disabledCspMessage'))
    }
  }, [dealers, t])

  // Setup the initial values
  const initialLicensePlate = searchParamsLicensePlate
    ? formatLicenseplate(searchParamsLicensePlate)
    : ''

  const prospectTypeValues = prospectTypes.map(
    (prospectType) => prospectType.value,
  )
  let initialProspectType = ''
  if (prospectTypeValues.length > 0) {
    initialProspectType = prospectTypeValues.includes(searchParamsProspectType)
      ? searchParamsProspectType
      : prospectTypeValues[0]
  }

  const dealerValues = dealers.map((dealer) => dealer.value)
  let initialDealer = ''
  if (dealerValues.length > 0) {
    initialDealer =
      dealerValues.includes(searchParamsDealer) &&
      !dealers.find((dealer) => dealer.value === searchParamsDealer).isDisabled
        ? searchParamsDealer
        : dealers.find((dealer) => !dealer.disabled).value
  }

  const initialValues = {
    licensePlate: initialLicensePlate,
    mileage: searchParamsMileage || '',
    prospectType: initialProspectType,
    dealer: initialDealer,
  }
  if (demo) {
    initialValues.mileagePerYear = searchParamsMileagePerYear
  }

  const onSubmitUpdates = (values) => {
    setFormError(null)
    const licensePlate = values.licensePlate.split('-').join('').toUpperCase()
    const {
      dealer: dealerId,
      mileage: currentMileage,
      prospectType: prospect,
      mileagePerYear,
    } = values

    const params = new URLSearchParams(location.search)
    params.set('licensePlate', licensePlate)
    params.set('dealerId', dealerId)
    params.set('currentMileage', currentMileage)
    params.set('prospect', prospect)
    if (mileagePerYear) {
      params.set('mileagePerYear', mileagePerYear)
    }
    if (demo) {
      params.set('demo', 'TRUE')
    }

    /**
     * A lazy query will be fired to check if there is a car with this licenseplate for this dealer.
     * The useLazyQuery function doesn't return a Promise, weirdly enough.
     * So, there is no way to wait here for the result of this query.
     * Instead, some manual state management is required to set some variables that are used in
     * the onCompleted and onError handlers of the lazy getCarFromLicensePlate query.
     * This might seem a bit cumbersome, but it's the cleanest solution.
     * At least until Apollo fixes this issue on their end and makes useLazyQuery return a Promise.
     */
    setSalesFlowStep1Url(
      `${CSP_SALES_FLOW}/${demo ? 1 : 0}?${params.toString()}`,
    )
    getCarFromLicensePlate({
      variables: {
        licensePlate,
        dealerId,
      },
    })
  }

  // Formik's validateOnMount property doesn't work properly.
  // So, some manual validation is required to initially set the form to (in)valid.
  let isInitiallyValid = true
  try {
    // This throws a ValidationError if validation fails. The catch block will be invoked then.
    validationSchema(t).validateSync(initialValues)
  } catch (error) {
    // Validation failed
    isInitiallyValid = false
  }

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema(t)}
      onSubmit={onSubmitUpdates}
      // Setting the intial errors to make the form invalid
      initialErrors={isInitiallyValid ? {} : initialValues}
    >
      {({ isValid }) => {
        const submitButton = (
          <>
            <Button level="option" onClick={switchFormMode} noPadding>
              {t('carServicePlanDashboard.switchToNoLicensePlateEntry')}
            </Button>
            <SubmitContainer>
              {carLoading && <StyledLoadingIndicator size="small" />}
              <StartCalculationButton
                type="submit"
                disabled={!isValid || everyDealerDisabled}
                level="cta"
                data-test-e2e="button-start-calculation"
              >
                {t('carServicePlanDashboard.startCalculation')}
              </StartCalculationButton>
            </SubmitContainer>
          </>
        )
        return (
          <Form data-test-e2e="start-service-calculation-form">
            <FormFields>
              <ContainerCell>
                <Field
                  name="licensePlate"
                  component={StyledFormikLicensePlateInput}
                  disabled={everyDealerDisabled}
                />
              </ContainerCell>

              <ContainerCell>
                <Field
                  name="mileage"
                  label={t('mileage')}
                  unit="km"
                  required
                  filled
                  component={FormikTextInput}
                  disabled={everyDealerDisabled}
                  id="mileage"
                />
              </ContainerCell>
              {demo ? (
                <>
                  <ContainerCell>
                    <Field
                      name="mileagePerYear"
                      items={getMileagePerYearOptions(t)}
                      label={t('cspSalesFlow.steps.basicData.mileagePerYear')}
                      filled
                      selectionRequired
                      required
                      component={FormikSelectInput}
                      disabled={everyDealerDisabled}
                      id="mileage-per-year"
                    />
                  </ContainerCell>

                  {submitButton}
                </>
              ) : (
                <>
                  <ContainerCell>
                    <Field
                      name="prospectType"
                      label={t('carServicePlanDashboard.prospectType')}
                      items={prospectTypes}
                      required
                      selectionRequired
                      filled
                      component={FormikSelectInput}
                      disabled={everyDealerDisabled}
                      id="prospect-type"
                    />
                  </ContainerCell>

                  <ContainerCell>
                    <CustomCspDealerField
                      name="dealer"
                      label={t('carServicePlanDashboard.dealer')}
                      items={dealers}
                      required
                      selectionRequired
                      filled
                      disabled={everyDealerDisabled}
                      id="dealer"
                    />
                  </ContainerCell>
                </>
              )}
            </FormFields>
            <FormSubmitLine>
              {formError && (
                <FormError type="ErrorText">
                  {formError}
                  {everyDealerDisabled && (
                    <ErrorTextLink
                      text={releaseCSPEmail}
                      href={`mailto:${releaseCSPEmail}`}
                    />
                  )}
                </FormError>
              )}

              {!demo && submitButton}
            </FormSubmitLine>
          </Form>
        )
      }}
    </Formik>
  )
}

StartServiceCalculationWithLicensePlate.propTypes = {
  /** If true, show fields required to start a demo flow */
  demo: PropTypes.bool,
  switchFormMode: PropTypes.func.isRequired,
  dealers: PropTypes.array.isRequired,
  prospectTypes: PropTypes.array.isRequired,
}

StartServiceCalculationWithLicensePlate.defaultProps = {
  demo: undefined,
}

export default StartServiceCalculationWithLicensePlate
