import Text from 'components/atoms/text'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
`

const Select = styled(Text)`
  border: none;
  border-radius: 0;
  background-color: transparent;
  height: 2.75em;
  padding-right: 30px;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;

  ${({ theme, $readOnly }) =>
    !$readOnly
      ? `
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20height%3D%225%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%2010%205%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23999%22%20stroke%3D%22%23999%22%20class%3D%22StyledSvg-sc-11vgbka-2%20ejApbw%22%3E%0A%20%20%20%20%3Cg%20stroke%3D%22none%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M0%200l5%205%205-5z%22%3E%3C%2Fpath%3E%0A%20%20%20%20%3C%2Fg%3E%3C%2Fsvg%3E');
      background-position: right;
      background-repeat: no-repeat;
      border-bottom: 1px solid ${theme.colors.brandAlfa};
    `
      : `
      color: ${theme.colors.textTonedDown};
      border-bottom: 1px solid ${theme.colors.textTonedDown};
      cursor: disabled;
    `}
`
/**
 * @deprecated use NewDropdownSelect instead
 */
function DropdownMenu({
  items,
  onChange,
  selected,
  className,
  placeholder,
  disabled,
}) {
  const { t } = useTranslation()

  function handleChange(event) {
    onChange(event.target.value)
  }

  return (
    <Container className={className}>
      <Select
        $readOnly={disabled}
        tag="select"
        type="input"
        value={selected}
        onChange={handleChange}
        disabled={disabled}
      >
        {!selected && <option value="">{placeholder || t('select')}</option>}
        {items.map((item, index) => (
          <option
            key={index.toString()}
            value={typeof item.value === 'undefined' ? index : item.value}
          >
            {item.label}
          </option>
        ))}
      </Select>
    </Container>
  )
}

DropdownMenu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
}

DropdownMenu.defaultProps = {
  className: null,
  placeholder: null,
  selected: null,
  disabled: false,
}

export default DropdownMenu
