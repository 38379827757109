import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
import EmptyInput from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import DropdownMenu from 'components/atoms/dropdown-menu'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components'
import { useMediaLayout } from 'use-media'
import { formatDataTestE2eAttr } from 'utilities/format'

function getMonthOptions() {
  return moment.months().map((label, value) => ({
    label,
    value,
  }))
}

function getYearOptions() {
  const years = Array.from(Array(120), (_, x) => moment().year() + 20 - x)

  return years.map((year) => ({
    label: year.toString(),
    value: year,
  }))
}

const Container = styled.div`
  width: 100%;
  cursor: pointer;
`

const MonthContainer = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    margin: 0 ${({ theme }) => theme.sizings.lvl1};
  }
`

const StyledFormControl = styled(FormControl)`
  ${({ error, required, theme, value }) =>
    !error &&
    required &&
    !value &&
    `
      .MuiInput-underline:before,
      .MuiInput-underline:after,
      .MuiInput-underline:hover:not(.Mui-disabled):before,
      .MuiFilledInput-underline:before,
      .MuiFilledInput-underline:after,
      .MuiFilledInput-underline:hover:not(.Mui-disabled):before {
        border-color: ${theme.colors.actionsCta};
      }

      .MuiFilledInput-root {
        background-color: ${theme.colors.requiredInputBackground};
      }
  `}

  .SingleDatePickerInput_clearDate__default:focus,
  .SingleDatePickerInput_clearDate__default:hover {
    background: ${({ theme }) => theme.colors.fieldBackground};
  }
`

const StyledFilledInput = styled(FilledInput)`
  cursor: pointer;
`

const StyledEmptyInput = styled(EmptyInput)`
  cursor: pointer;
`

function MonthElement({ month, onMonthSelect, onYearSelect }) {
  return (
    <MonthContainer style={{ display: 'flex', justifyContent: 'center' }}>
      <DropdownMenu
        items={getMonthOptions()}
        onChange={(value) => onMonthSelect(month, value)}
        selected={month.month()}
      />
      <DropdownMenu
        items={getYearOptions()}
        onChange={(value) => onYearSelect(month, value)}
        selected={month.year()}
      />
    </MonthContainer>
  )
}

MonthElement.propTypes = {
  month: PropTypes.object.isRequired,
  onMonthSelect: PropTypes.func.isRequired,
  onYearSelect: PropTypes.func.isRequired,
}

/**
 * Date picker wrapped in
 * [MaterialUI's FormControl](https://material-ui.com/api/form-control/)
 */
function DatePicker({
  className,
  disabled,
  error,
  filled,
  label,
  name,
  onChange,
  required,
  value,
  placeholder,
  isOutsideRange,
  id,
  clearable,
}) {
  const date = value ? moment(value) : null
  const theme = useContext(ThemeContext)
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tablet - 1 })
  const { t } = useTranslation()
  const [focused, setFocused] = useState()
  const Input = filled ? StyledFilledInput : StyledEmptyInput

  function handleDateChange(newDate) {
    onChange(newDate)
  }

  function handleFocusChange(values) {
    setFocused(values.focused)
  }

  return (
    <Container className={className}>
      <label>
        <StyledFormControl
          error={!!error}
          required={required}
          disabled={disabled}
          variant={filled && 'filled'}
          value={value}
          fullWidth
          id={name}
          data-test-e2e={formatDataTestE2eAttr(id || name, 'container')}
        >
          <InputLabel htmlFor={name} filled={filled} shrink>
            {label}
          </InputLabel>
          <Input
            data-test-e2e={formatDataTestE2eAttr(id || name)}
            inputComponent={() => (
              <SingleDatePicker
                id={name}
                date={date}
                disabled={disabled}
                displayFormat="DD/MM/YYYY"
                focused={focused}
                isOutsideRange={isOutsideRange}
                numberOfMonths={1}
                onDateChange={handleDateChange}
                onFocusChange={handleFocusChange}
                placeholder={placeholder || t('pickDate')}
                renderMonthElement={MonthElement}
                withPortal={isMobile}
                appendToBody
                showClearDate={clearable}
                noBorder
                readOnly
                small
              />
            )}
          />
        </StyledFormControl>
      </label>
    </Container>
  )
}

DatePicker.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  filled: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isOutsideRange: PropTypes.func,
  id: PropTypes.string,
  clearable: PropTypes.bool,
}

DatePicker.defaultProps = {
  placeholder: null,
  className: null,
  error: false,
  filled: false,
  disabled: false,
  required: false,
  value: null,
  isOutsideRange: () => false,
}

export default DatePicker
