import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { reset } from 'redux-form'
import styled from 'styled-components/macro'

import * as routes from 'config/routes'

import { mapCarFileType } from 'utilities/mapping'
import { media } from 'utilities/styled'
import { scrollTo } from 'utilities/utils'

import Button from 'components/atoms/button'
import LoadingIndicator from 'components/atoms/loading-indicator'
import Text from 'components/atoms/text'
import TextLink from 'components/atoms/text-link'
import EnhancedCard from 'components/layouts/enhanced-card'
import CarVersionSearch from 'components/organisms/car-version-search'
import CarVersionSelector from 'components/organisms/car-version-selector'
import PageHeader from 'components/organisms/page-header'
import { IGNORE_EXISTING_CAR_FILE } from 'config/consts'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.brandFoxtrot};
  padding: 0 ${({ theme }) => theme.sizings.lvl3};

  ${media.desktop`
    padding: 0 60px;
  `};
`

// This negative margin makes the content full width in mobile
const ContentContainer = styled(EnhancedCard)`
  margin: 0 -${({ theme }) => theme.sizings.lvl3};
  ${({ theme, $withMarginTop }) =>
    $withMarginTop && `margin-top: ${theme.sizings.lvl2};`}

  ${media.tablet`
    margin: 0;
    ${({ theme, $withMarginTop }) => $withMarginTop && `margin-top: ${theme.sizings.lvl2};`}
  `};
`

const NothingFound = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.sizings.lvl3};
  text-align: center;

  & > * {
    margin-bottom: 1em;
  }
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: ${({ theme }) => theme.sizings.lvl3};
`

function CarEntryStep1({
  addNewCarData,
  clearData,
  getLicensePlate,
  licensePlate,
  newCar,
  prefilledLicensePlate,
  prefilledMileage,
}) {
  const { t } = useTranslation()
  const carVersionSelector = useRef()
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const checkForExistingCarFile =
    !searchParams.get(IGNORE_EXISTING_CAR_FILE) ?? true
  const [entryAlthoughExisting, setEntryAlthoughExisting] = useState(false)
  const [gotLicensePlate, setGotLicensePlate] = useState(false)
  const dispatch = useDispatch()
  const car = licensePlate && licensePlate.data
  const editions = car && car.uitvoeringen
  const loading = licensePlate && licensePlate.loading
  const existingCarId =
    licensePlate && licensePlate.data && licensePlate.data.exists

  // Clear all car entry data when (re-)entering this view
  useEffect(() => {
    clearData('newCar')
    clearData('edition')
    clearData('licensePlate')
    clearData('nap')
    clearData('editions')
    clearData('editionsForBpmDeclaration')
    clearData('carAssets')
    clearData('carTypes')
    clearData('carModels')
    clearData('carBrands')
    clearData('createdCarfileByEdition')
    clearData('carfile')
    clearData('forms')
    dispatch(reset('carEntry'))
    dispatch(reset('carValuation'))
  }, [clearData, dispatch])

  useEffect(() => {
    if (!checkForExistingCarFile) {
      setEntryAlthoughExisting(true)
    } else if (existingCarId && gotLicensePlate && !entryAlthoughExisting) {
      if (window.confirm(t('confirmGoToExistingCar'))) {
        clearData('licensePlate')
        history.push(`${routes.CAR_FILE}/${existingCarId}`)
      } else {
        setEntryAlthoughExisting(true)
      }
    }
  }, [
    checkForExistingCarFile,
    clearData,
    entryAlthoughExisting,
    existingCarId,
    gotLicensePlate,
    history,
    t,
  ])

  useEffect(() => {
    if (editions && carVersionSelector.current) {
      scrollTo(carVersionSelector.current)
    }
  }, [editions])

  function handleGetLicensePlate(data) {
    setGotLicensePlate(true)
    getLicensePlate(data)
  }

  function handleEnrichWithoutEditions() {
    const step2Route = `${routes.CAR_ENTRY}/2`

    addNewCarData({
      ...car,
    })

    history.push(step2Route)
  }

  return (
    <Container>
      <PageHeader
        mainTitle={mapCarFileType(t, car?.voertuigsoort_mapped)}
        status={t('entry')}
        subTitle={t('step12SearchCar')}
      />
      <ContentContainer>
        <CarVersionSearch
          handleGetLicensePlate={handleGetLicensePlate}
          prefilledLicensePlate={prefilledLicensePlate}
          prefilledMileage={prefilledMileage}
        />
      </ContentContainer>

      <div ref={carVersionSelector}>
        {loading && <StyledLoadingIndicator />}
        {!loading &&
          editions &&
          editions.length > 0 &&
          (!existingCarId || entryAlthoughExisting) &&
          car && (
            <ContentContainer
              $withMarginTop
              data-test-e2e="car-version-selector"
            >
              <CarVersionSelector
                addNewCarData={addNewCarData}
                car={car}
                editions={editions}
                newCar={newCar}
              />
            </ContentContainer>
          )}
      </div>

      {!loading && car && editions && editions.length === 0 && (
        <NothingFound>
          <Text text={t('thereAreNoKnownEditions')} />
          <Button
            text={t('continueEnriching')}
            onClick={handleEnrichWithoutEditions}
          />
        </NothingFound>
      )}
      {!loading && car && !editions && (
        <NothingFound>
          <Text text={t('weCantFindLicensePlate')} />
          <Text text={t('youCanEnterCarTrough')} />
          <TextLink
            text={t('noLicensePlate')}
            to={`${routes.CAR_ENTRY}/nolicenseplate`}
          />
          <TextLink
            text={t('orChooseManualEntry')}
            to={`${routes.CAR_ENTRY}/manual`}
          />
        </NothingFound>
      )}
    </Container>
  )
}

CarEntryStep1.propTypes = {
  addNewCarData: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  getLicensePlate: PropTypes.func.isRequired,
  licensePlate: PropTypes.object,
  newCar: PropTypes.object,
  prefilledLicensePlate: PropTypes.string,
  prefilledMileage: PropTypes.number,
}

CarEntryStep1.defaultProps = {
  licensePlate: null,
  newCar: null,
  prefilledLicensePlate: '',
  prefilledMileage: null,
}

export default CarEntryStep1
