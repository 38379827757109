import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Popper from '@material-ui/core/Popper'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import React, { useContext, useRef } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'
import { useMediaLayout } from 'use-media'

import Counter from 'components/atoms/counter'
import NavigationBarNewsContent from 'components/molecules/navigation-bar-news-content'

import { QuickActionButton } from 'components/organisms/navigation-bar'
import { media } from 'utilities/styled'

const Container = styled.div`
  position: relative;
  flex-shrink: 0;
`

const StyledCounter = styled(Counter)`
  position: absolute;
  top: -${({ theme }) => theme.sizings.lvl0};
  right: 0;

  ${media.tablet`
      right: -${({ theme }) => theme.sizings.lvl0};
  `}
`

const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    align-items: ${({ $isMobile }) => ($isMobile ? 'flex-end' : 'center')};
  }

  .MuiPaper-root {
    background: ${({ theme }) => theme.colors.isolatedBackground};
    box-shadow: ${({ theme }) => theme.shadows.modal};
    margin: 0;
    width: 100%;
  }

  .MuiBackdrop-root {
    background: ${({ theme }) => theme.colors.brandAlfa}cc;
  }

  .MuiDialogContent-root {
    background-color: white;
    padding: ${({ theme }) => theme.sizings.lvl2};
  }
`

const StyledPopper = styled(Popper)`
  z-index: 11;
`

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const NavigationBarNews = ({
  className,
  showPopout,
  setShowPopout,
  notificationCount,
  newsItem,
}) => {
  const theme = useContext(ThemeContext)
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tablet - 1 })
  const isDesktop = useMediaLayout({ minWidth: theme.metrics.desktop })
  const buttonRef = useRef(null)
  const contentRef = useRef(null)

  const handleClickAway = (event) => {
    var popperContainer = document.getElementById('popper-container')

    if (
      event.target !== popperContainer &&
      !popperContainer.contains(event.target)
    ) {
      handleClick()
    }
  }

  const handleClick = () => {
    if (notificationCount === 0 && !showPopout) {
      window.open(
        'https://go-remarketing.com/actueel/?utm_source=ucc_app&utm_medium=aandacht_popup_nieuwsoverzicht_button',
        '_blank',
      )
      return
    }

    if (!showPopout) {
      setShowPopout(true)
    }

    if (showPopout) {
      setShowPopout(false)
    }
  }

  return (
    <>
      <Container className={className}>
        {!!notificationCount && <StyledCounter count={notificationCount} />}
        <span ref={buttonRef}>
          <QuickActionButton
            testId="newsButton"
            icon="news"
            level="standard"
            onClick={handleClick}
            data-test-e2e="button-news"
          />
        </span>
      </Container>

      {!isMobile && newsItem && !!buttonRef?.current && showPopout && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div id="popper-container">
            <StyledPopper
              anchorEl={buttonRef.current}
              open={showPopout}
              placement={isDesktop ? 'top-start' : 'top-end'}
              modifiers={{
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: true,
                  boundariesElement: 'viewport',
                },
              }}
              popperOptions={{
                modifiers: {
                  offset: {
                    offset: isDesktop ? '6, -32' : '0, 0',
                  },
                },
              }}
            >
              <NavigationBarNewsContent
                ref={contentRef}
                newsItem={newsItem}
                handleClose={handleClick}
              />
            </StyledPopper>
          </div>
        </ClickAwayListener>
      )}

      {isMobile && newsItem && (
        <StyledDialog
          disableEnforceFocus
          disablePortal
          PaperProps={{ square: true }}
          open={showPopout}
          className={className}
          disableBackdropClick={false}
          onClose={handleClick}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          TransitionComponent={isMobile ? Transition : undefined}
          $isMobile={isMobile}
        >
          <DialogContent>
            <NavigationBarNewsContent
              newsItem={newsItem}
              handleClose={handleClick}
            />
          </DialogContent>
        </StyledDialog>
      )}
    </>
  )
}

NavigationBarNews.propTypes = {
  className: PropTypes.string,
  showPopout: PropTypes.bool.isRequired,
  setShowPopout: PropTypes.func.isRequired,
  notificationCount: PropTypes.number.isRequired,
  newsItem: PropTypes.shape({
    img: PropTypes.string,
    title: PropTypes.string.isRequired,
    intro: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
}

NavigationBarNews.defaultProps = {
  className: null,
  newsItem: null,
}

export default NavigationBarNews
